import React from 'react'
import TopNavigation from 'components/TopNavigation'
import Impressum from 'components/Impressum'
import { hooks } from 'lq-ui'

const ImpressumPage = () => (
  <>
    <TopNavigation title={hooks.i18n.useMessage('imprint.main')} />
    <Impressum />
  </>
)

export default ImpressumPage
