import React from 'react'
import PropTypes from 'prop-types'
import routes from 'pages/routes.json'
import {
  Button,
  TextField,
  useAuth,
  hooks,
} from 'lq-ui'
import styles from './SignInFormStyles'

const SignInForm = ({ emailLabel, passwordLabel, signInLabel }) => {
  const classes = styles()
  const auth = useAuth()
  const router = hooks.router.useRouter()
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    error: false,
  })

  const handleSubmit = async () => {
    const isAuth = await auth.signin(values.email, values.password)
    if (isAuth) {
      router.push(routes.editor)
    } else {
      setValues({ ...values, error: true })
    }
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value, error: false })
  }
  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit}>
      <TextField
        value={values.email}
        autoComplete
        autoFocus
        label={emailLabel}
        onChange={handleChange('email')}
        required
        type="email"
      />
      <TextField
        value={values.password}
        autoComplete
        label={passwordLabel}
        onChange={handleChange('password')}
        required
        type="password"
      />
      <Button
        fullWidth
        color="#760074"
        className={classes.submit}
        onClick={handleSubmit}
      >
        {signInLabel}
      </Button>
      {values.error && (
      <span className={classes.validation}>
        Es tut mir leid, der Benutzername oder das Passwort stimmt leider nicht.
      </span>
      )}
    </form>
  )
}

SignInForm.propTypes = {
  emailLabel: PropTypes.string.isRequired,
  passwordLabel: PropTypes.string.isRequired,
  signInLabel: PropTypes.string.isRequired,
}

export default SignInForm
