import React from 'react'
import { hooks } from 'lq-ui'
import Layout from 'components/Layout'
import Meetings from 'components/Meetings'

const MeetingsPage = () => (

  <Layout
    MeetingsPage
    bottomtitle={hooks.i18n.useMessage('landing.imprint')}
    toptitle={hooks.i18n.useMessage('meetings.toptitle')}
  >
    <Meetings />
  </Layout>
)

export default MeetingsPage
