import React from 'react'
import NodeInformation from 'components/NodeInformation'
import { nodeSelector } from 'redux/selectors'
import { useSelector } from 'react-redux'
import ConfiguratorBox from './ConfiguratorBox'

const NodeInfoConfigurator = () => {
  const node = useSelector(nodeSelector)
  return <div>{node ? <NodeInformation /> : <ConfiguratorBox />}</div>
}

export default NodeInfoConfigurator
