import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    container: {
      display: 'flex',
      width: '100%',
      overflow: 'hidden',
      border: '1px solid #085986',
      borderRadius: '4px',
      alignItems: 'center',
    },
    heading: {
      whiteSpace: 'normal !important',
      padding: '5px !important',
      overflow: 'hidden',
      fontSize: 'larger',
      color: '#085986',
    },
    infoIcon: {
      padding: '5px',
      fontSize: '2rem',
      float: 'left',
      verticalAlign: 'middle',
      color: '#209BCD',
    },
  })
  return useStyles()
}
