import React from 'react'
import { TextField, Grid } from 'lq-ui'
import PropTypes from 'prop-types'
import styles from './ProviderDescriptionStyles'

const ProviderDescription = ({
  editing, onChange, label, value,
}) => {
  const classes = styles()
  return editing ? (
    <Grid type="item" xs={12} className={classes.textColor}>
      <TextField
        value={value}
        label={label}
        rows="5"
        onChange={onChange}
      />
    </Grid>
  ) : <Grid type="item" xs={12} className={classes.textColor}>{value}</Grid>
}

ProviderDescription.defaultProps = {
  editing: false,
  onChange: () => null,
  label: '',
}

ProviderDescription.propTypes = {
  editing: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
}

export default ProviderDescription
