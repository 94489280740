import { useJss } from 'lq-ui'

export default (isEditor) => {
  const useStyles = useJss({
    textField: {
      marginLeft: '1rem',
      marginRight: isEditor ? '1rem' : 'inherit',
      width: isEditor ? 'initial' : '18rem',
    },
  })
  const classes = useStyles()
  return classes
}
