import React from 'react'
import PropTypes from 'prop-types'
import { Select, Grid } from 'lq-ui'

const categories = [
  { label: 'Ausbildung', value: 'apprenticeship' },
  { label: 'Arbeit', value: 'job' },
  { label: 'Anerkennung', value: 'approval' },
  { label: 'Status', value: 'status' },
  { label: 'Praktikum', value: 'internship' },
  { label: 'Sprache', value: 'language' },
]

const ProviderSelectType = ({
  editing, onChange, optionsLabel, value, label,
}) => editing && (
  <Grid type="item" xs={12}>
    <Select
      // className={classes.select}
      inputLabel={label}
      value={value || null}
      textColor="#085986"
      items={
        [...(value
          ? [{ label: optionsLabel, value: '' }]
          : []), ...(categories || [])]
      }
      onChange={onChange}
    />
  </Grid>
)

ProviderSelectType.propTypes = {
  editing: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  optionsLabel: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default ProviderSelectType
