import { useJss } from 'lq-ui'

export default (size, badgeColor, borderColor) => {
  const bubbleSize = size
  const bubbleButtonSize = bubbleSize * 1.05
  const bubbleIconSize = (bubbleSize / 2.5)

  const basicFab = {
    width: `${bubbleButtonSize}px !important`,
    height: `${bubbleButtonSize}px !important`,
    textTransform: 'capitalize !important',
  }

  const useStyles = useJss({
    bubbleFab: {
      ...basicFab,
      backgroundColor: `${borderColor} !important`,
    },
    badge: {
      zIndex: '0',
      '& span': {
        backgroundColor: badgeColor || '#760074',
        top: `${bubbleSize / 10}px`,
        fontSize: `${bubbleSize * 222.222} rem`,
        right: `${bubbleSize / 8}px`,
      },
    },
    bubbleContent: {
      textAlign: 'center',
    },
    bubbleIcon: {
      color: 'white',
      fontSize: bubbleIconSize,
      padding: `${bubbleSize / 25}px`,
    },
    bubbleLabel: {
      fontSize: `${bubbleSize / 160}rem`,
    },
  })
  return useStyles()
}
