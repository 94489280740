import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Card, Button, hooks } from 'lq-ui'
import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import ProviderMap from 'components/ProviderMap'
import { updateMeetingInformation, proposeMeeting } from 'redux/actions'
import { useDispatch } from 'react-redux'
import useAddress from './useAddress'
import styles from './styles'

const MeetingsForm = ({
  meeting, toggleEditing, editing, isNew, toggleNewMeeting, user,
}) => {
  const address = useAddress(meeting.address, meeting.map)
  const dispatch = useDispatch()
  const classes = styles()
  const defaultValues = {
    name: meeting.name || '',
    email: meeting.email || '',
    phone: meeting.phone || '',
    serviceHours: meeting.serviceHours || '',
    contactName: meeting.contactName || '',
    address: address.value || '',
    shortDescription: meeting.shortDescription || '',
    provider: meeting.provider || '',
    district: meeting.district || '',
    map: address.map,
  }
  const {
    handleSubmit, control, errors, reset,
  } = useForm({ defaultValues })

  const handleUpdate = (data) => {
    if (isNew) {
      toggleNewMeeting(!isNew)
    } else {
      toggleEditing(false)
    }
    dispatch(user
      ? updateMeetingInformation({
        ...data, id: meeting.id, address: address.value, map: address.map, district: data.district.trim(),
      })
      : proposeMeeting({
        ...data,
        id: meeting.id,
        address: address.value,
        map: address.map,
        district: data.district.trim(),
        proposedBy: data.proposedBy.trim(),
      }))
  }
  const renderTextField = (rows) => (
    <TextField fullWidth variant="outlined" margin="normal" rows={rows} multiline={!!rows} />
  )

  return (
    <Card>
      <form
        onSubmit={handleSubmit((data) => handleUpdate(data))}
        style={{ padding: '1rem' }}
      >
        <Controller
          as={renderTextField()}
          name="name"
          control={control}
          label={hooks.i18n.useMessage('meeting.card.name')}

          rules={{ required: true }}
        />
        <Controller
          as={renderTextField()}
          name="email"
          control={control}
          label={hooks.i18n.useMessage('meeting.card.email')}

        />
        <Controller
          as={renderTextField()}
          name="phone"
          control={control}
          label={hooks.i18n.useMessage('meeting.card.phone')}

        />
        <Controller
          as={renderTextField()}
          name="serviceHours"
          control={control}
          label={hooks.i18n.useMessage('meeting.card.serviceHours')}

        />
        <Controller
          as={renderTextField()}
          name="contactName"
          control={control}
          label={hooks.i18n.useMessage('meeting.card.contactName')}

        />
        <Controller
          as={renderTextField()}
          name="address"
          control={control}
          label={hooks.i18n.useMessage('meeting.card.address')}
          onChange={([e]) => address.onChange(e)}
          onBlur={([e]) => address.onBlur(e)}
        />
        <ProviderMap address={address} editing={editing} />
        <Controller
          as={renderTextField(5)}
          name="shortDescription"
          control={control}
          label={hooks.i18n.useMessage('meeting.card.shortDescription')}

        />
        <Controller
          as={renderTextField()}
          name="provider"
          control={control}
          label={hooks.i18n.useMessage('meeting.card.provider')}

        />
        <Controller
          as={renderTextField()}
          name="district"
          control={control}
          label={hooks.i18n.useMessage('meeting.card.district')}

          rules={{ required: true }}
        />
        {errors?.district && <span className={classes.error}>Geben Sie ein Ort </span>}
        {!user
        && (
          <>
            <Controller
              as={renderTextField()}
              name="proposedBy"
              control={control}
              label={hooks.i18n.useMessage('meeting.card.proposedBy')}

              rules={{ required: true }}
            />
            {errors?.proposedBy && <span className={classes.error}>Geben Sie Ihre E-Mail-Adresse</span>}
          </>
        )}
        <div className={classes.savingButtons}>
          <Button type="submit" className={classes.saveButton}>{hooks.i18n.useMessage('general.confirm')}</Button>
          <Button
            className={classes.cancelButton}
            type="button"
            onClick={() => {
              if (isNew) {
                toggleNewMeeting(!isNew)
              } else {
                toggleEditing(false)
              }
              reset(defaultValues)
            }}
          >
            {hooks.i18n.useMessage('general.cancel')}
          </Button>
        </div>
      </form>
    </Card>
  )
}

MeetingsForm.defaultProps = {
  meeting: {},
  isNew: false,
  toggleEditing: () => null,
  toggleNewMeeting: () => null,
  user: false,
}

MeetingsForm.propTypes = {
  meeting: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    serviceHours: PropTypes.string,
    contactName: PropTypes.string,
    shortDescription: PropTypes.string,
    provider: PropTypes.string,
    district: PropTypes.string,
    address: PropTypes.string,
    map: PropTypes.string,
  }),
  toggleEditing: PropTypes.func,
  toggleNewMeeting: PropTypes.func,
  editing: PropTypes.bool.isRequired,
  isNew: PropTypes.bool,
  user: PropTypes.bool,
}

export default MeetingsForm
