import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'lq-ui'
import styles from './ProviderViewStyles'

const ProviderView = ({ children }) => {
  const classes = styles()
  return (
    <Card className={classes.card}>
      { children }
    </Card>
  )
}

ProviderView.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]).isRequired,
}

export default ProviderView
