import React, { useState } from 'react'
import { hooks } from 'lq-ui'
import Layout from 'components/Layout'
import GraphConfiguration from 'components/GraphConfiguration'
import MobileRotationHint from 'components/MobileRotationHint'

const GraphPage = () => {
  const isMobile = (
    (window.innerWidth <= 414 && window.innerHeight <= 823) || (window.innerWidth <= 823 && window.innerHeight <= 414))
  const isLandscapeMode = window.orientation === 90 || window.orientation === -90

  const [graphPageContent, setGraphPageContent] = useState(isMobile && !isLandscapeMode
    ? <MobileRotationHint />
    : <GraphConfiguration />)
  const { i18n: { useMessage } } = hooks
  window.addEventListener('orientationchange', () => {
    if (!isLandscapeMode) {
      setGraphPageContent(<GraphConfiguration />)
    } else if (isLandscapeMode && isMobile) {
      setGraphPageContent(<MobileRotationHint />)
    }
  })

  return (
    <main>
      <Layout
        GraphPage
        bottomtitle={useMessage('landing.imprint')}
        toptitle={useMessage('graph.toptitle')}
      >
        {graphPageContent}
      </Layout>
    </main>
  )
}

export default GraphPage
