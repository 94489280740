import React from 'react'
import { useSelector } from 'react-redux'
import { computeNextCondition } from 'redux/selectors'
import ConditionComponent from 'components/ConditionComponent'

const Persona = () => {
  const { persona } = useSelector((state) => state.appState)
  const nextPersonaCondition = useSelector(computeNextCondition)
  return (
    <div>
      {
        Object.keys(persona).map((p) => <ConditionComponent condition={p} isCriteria key={p} />)
      }
      <ConditionComponent condition={nextPersonaCondition} isCriteria />
    </div>
  )
}

export default Persona
