import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    layout: {
      height: 'calc(100% - 65px)',
      overflow: 'overlay',
    },
  })
  return useStyles()
}
