import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    savingButtons: {
      display: 'flex',
      marginTop: '1rem',
    },
    saveButton: {
      width: '50%',
      backgroundColor: '#760074',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#760074',
        color: '#fff',
      },
    },
    cancelButton: {
      width: '50%',
      backgroundColor: '#757575',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#757575',
      },
    },
    error: {
      color: '#B00020',
      padding: '.5rem',
    },
  })
  const classes = useStyles()
  return classes
}
