import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    expansion: {
      marginTop: '5rem',
      width: '100%',
      bottom: '0',
    },
  })

  const classes = useStyles()
  return classes
}
