import React from 'react'
import { hooks, Heading } from 'lq-ui'
import styles from './ProvidersInfoStyles'

const ProvidersInfo = () => {
  const isMobile = hooks.window.useIsMobile()
  const classes = styles()
  const message1 = hooks.i18n.useMessage('providers.info.1')
  const message2 = hooks.i18n.useMessage('providers.info.2')
  const message3 = hooks.i18n.useMessage('providers.info.3')
  return isMobile
    ? (
      <div className={classes.mobile}>
        <Heading level="h4" color="#085986">
          <b>{message1}</b>
          <br />
          <br />
          <i>{message2}</i>
          <br />
          <i>{message3}</i>
        </Heading>
      </div>
    ) : (
      <div className={classes.info}>
        <Heading level="h6" color="#085986" className={classes.infoMessage}>
          {message1}
          <br />
          {message2}
          <br />
          <i>{message3}</i>
        </Heading>
        <img src="../providers.png" alt="TEST" className={classes.image} />
      </div>
    )
}

export default ProvidersInfo
