import { combineReducers } from 'redux'
import graphReducer from './graphReducer'
import selectedProviderReducer from './selectedProviderReducer'
import appStateReducer from './appStateReducer'
import proposalsReducer from './proposalsReducer'

// import updatedCriteria from './updatedCriteria'
// import updateCriteriaValues from './updateCriteriaValues'
// import updatedConditions from './updatedConditions'
// import selectedGraphReducer from './selectedGraphReducer'

export default combineReducers({
  graph: graphReducer,
  selectedProvider: selectedProviderReducer,
  appState: appStateReducer,
  proposals: proposalsReducer,

  // selectedGraph: selectedGraphReducer,
  // criteria: updatedCriteria,
  // conditions: updatedConditions,
  // criteriaValues: updateCriteriaValues,
})
