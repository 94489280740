const options = {
  edges: {
    arrowStrikethrough: false,
    chosen: true,
    color: {
      color: '#86D4EA',
      highlight: '#86D4EA',
      hover: '#848484',
      inherit: 'from',
      opacity: 1,
    },
    dashes: false,
    hidden: false,
    hoverWidth: 1.5,
    length: undefined,
    physics: true,
    scaling: {
      min: 1,
      max: 1,
      label: {
        enabled: true,
        min: 1,
        max: 1,
        maxVisible: 30,
      },
    },
    selectionWidth: 1,
    selfReferenceSize: 20,
    smooth: {
      enabled: true,
      type: 'dynamic',
      roundness: 0.5,
    },
    title: undefined,
    value: undefined,
    width: 1,
    widthConstraint: false,
  },
  layout: {
    hierarchical: {
      direction: 'UD',
      sortMethod: 'directed',
    },
  },
  physics: {
    hierarchicalRepulsion: {
      avoidOverlap: 50,
    },
  },
  nodes: {
    fixed: {
      x: false,
      y: true,
    },
    chosen: { label: false, node: false },
    margin: 10,
    scaling: {
      min: 10,
      max: 30,
      label: {
        enabled: false,
        min: 1,
        max: 50,
        maxVisible: 50,
        drawThreshold: 0,
      },
    },
  },
  interaction: {
    zoomView: true,
    dragNodes: false,
  },
  groups: {
    gate: {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Pro'",
        weight: 'bold', // Font Awesome 5 doesn't work properly unless bold.
        code: '\uf1ad',
        color: '#085986',
      },
    },
    course: {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Pro'",
        weight: 'bold', // Font Awesome 5 doesn't work properly unless bold.
        code: '\uf63d',
        color: '#209BCD',
      },
    },
    start: {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Pro'",
        weight: 'bold', // Font Awesome 5 doesn't work properly unless bold.
        code: '\uf21d',
        color: '#00A476',
      },
    },
    end: {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Pro'",
        weight: 'bold', // Font Awesome 5 doesn't work properly unless bold.
        code: '\uf11e',
        color: '#00A476',
      },
    },
  },
}


export default options
