import React from 'react'
import PropTypes from 'prop-types'
import { PrintView, hooks } from 'lq-ui'
import ProvidersGrid from 'components/ProvidersGrid'
import ProvidersInfo from 'components/ProvidersInfo'

import OnBoarding from 'assets/onboarding.png'

const ProvidersList = ({ providers, isPrinting, addProvider }) => {
  const router = hooks.router.useRouter()

  return (
    router.query.type
      ? (
        <>
        { router.query.type === 'onboarding' 
          ? <>
              <img src={OnBoarding} alt="Onboarding Informationen" style={{width: '100%'}} />
              { isPrinting && (
                <PrintView>
                  <img src={OnBoarding} alt="Onboarding Informationen" style={{width: '100%'}} />
                </PrintView>
              )}
            </>
          : <>
              <ProvidersGrid providers={providers} isEditor fixedWidth />
              { isPrinting && (
                <PrintView>
                  <ProvidersGrid providers={providers} showDescription isPrintingList={isPrinting} />
                </PrintView>
              )}
            </>
        }
        </>
      )
      : !addProvider
        ? <ProvidersInfo />
        : null
  )
}

ProvidersList.defaultProps = {
  isPrinting: false,
  addProvider: false,
}

ProvidersList.propTypes = {
  providers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    map: PropTypes.string,
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  isPrinting: PropTypes.bool,
  addProvider: PropTypes.bool,
}

export default ProvidersList
