import { useSelector } from 'react-redux'
import { nodeConnectedGraphSelector, computeGraph, nodeSelector } from 'redux/selectors'

const useNodeInfo = () => {
  const selectedNode = useSelector(nodeSelector)
  const { providers } = useSelector((state) => state.graph) || []
  const graph = useSelector(computeGraph)
  const { edges } = useSelector((state) => nodeConnectedGraphSelector(state))
  const node = selectedNode !== null ? graph.nodes.find((n) => n.id === selectedNode) : 0

  const nodeProviders = node ? providers.filter((p) => node.providers.includes(p.id)) : null

  const nodeConditions = edges.length
    ? edges.map((e) => Object.keys(e)
      .reduce((acc, k) => (['from', 'to', 'id'].includes(k)
        ? acc : { ...acc, [k]: e[k] }), {}))
      .map((c) => Object.entries(c).reduce((a, [k, v]) => (v ? { ...a, [k]: v } : a), {}))
      .map((c) => Object.values(c))
    : []
  return [node, nodeConditions, nodeProviders]
}

export default useNodeInfo
