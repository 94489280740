import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    container: {
      height: 'calc(100% - 65px)',
    },
    graphContainer: {
      padding: '.5rem 1rem',
      height: '100%',
    },
    legends: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    legendIcon: {
      padding: '5px',
    },
    graph: {
      height: 'calc(100% - 65px)',
    },
    sideNavContainer: {
      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
      height: '100%',
      overflowY: 'scroll',
    },
  })
  const classes = useStyles()
  return classes
}
