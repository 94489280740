import React from 'react'
import { ReactComponent as Logo } from 'assets/landingPage/ditoLogo.svg'
import styles from './BubbleDitoStyles'

const BubbleDito = () => {
  const classes = styles()
  return (
    <Logo className={classes.ditoLogo} />
  )
}

export default BubbleDito
