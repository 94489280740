import React from 'react'
import PropTypes from 'prop-types'
import { Card, TextField } from 'lq-ui'
import ProviderPrintButton from 'components/ProviderPrintButton'
import styles from './ProviderNameStyles'

const ProviderName = ({
  title, handlePrint, editing, onChangeTitle, label, isEditor, noButton,
}) => {
  const classes = styles(isEditor)
  return (
    editing ? (
      <TextField
        value={title.value}
        label={label}
        onChange={onChangeTitle}
        className={classes.textField}
      />
    ) : (
      <Card.Header
        textColor="#085986"
        title={title.value}
        action={
          <ProviderPrintButton noButton={noButton} handlePrint={handlePrint} />
        }
      />
    ))
}

ProviderName.defaultProps = {
  editing: false,
  isEditor: false,
  handlePrint: null,
  label: '',
  noButton: false,
  onChangeTitle: null,
  title: null,
}

ProviderName.propTypes = {
  editing: PropTypes.bool,
  handlePrint: PropTypes.func,
  label: PropTypes.string,
  noButton: PropTypes.bool,
  isEditor: PropTypes.bool,
  onChangeTitle: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default ProviderName
