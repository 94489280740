import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    paper: {
      margin: '15px 10px 10px 10px',
      height: '100%',
      width: '-webkit-fill-available',
      whiteSpace: 'pre-line',
    },
    button: {
      borderRadius: '4px',
      padding: '6px 8px',
      marginBottom: '.5rem',
      backgroundColor: '#209BCD',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#760074',
        color: '#fff',
      },
    },
  })
  return useStyles()
}
