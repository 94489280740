import React from 'react'
import styles from 'components/NodeInfoBox/NodeInfoBoxStyles'
import useNodeInfo from 'services/useNodeInfo'

const NodeInfoBox = () => {
  const [node] = useNodeInfo()
  const classes = styles()
  return node ? (
    <div className={classes.container}>
      <i className={`fas fa-info-circle ${classes.infoIcon}`} />
      <div className={classes.heading}>
        {node.shortDescription}
      </div>
    </div>
  ) : ''
}

export default NodeInfoBox
