import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { updateConditions, updateCriteria } from 'redux/actions'
import { Select, hooks } from 'lq-ui'
import { computeNextCondition, getPersona } from 'redux/selectors'

const RenderSelectCondition = ({
  condition, conditions, selectOptions,
}) => {
  const nextCondition = useSelector(computeNextCondition)
  const persona = useSelector(getPersona)
  const dispatch = useDispatch()
  const handleChange = (value) => {
    if (nextCondition === condition || Object.keys(persona).includes(condition)) {
      dispatch(updateCriteria({ [condition]: value }))
      dispatch(updateConditions({ [condition]: value }))
    } else {
      dispatch(updateConditions({ [condition]: value }))
    }
  }
  const optionsLabel = hooks.i18n.useMessage('component.select.options')
  return (
    <Select
      inputLabel={condition}
      value={conditions[condition] === undefined ? '' : conditions[condition]}
      items={
        [...(conditions[condition] !== undefined && conditions[condition] !== ''
          ? [{ label: optionsLabel, value: '' }]
          : []), ...selectOptions]
        }
      textColor="#085986"
      onChange={(value) => handleChange(value)}
    />
  )
}

RenderSelectCondition.defaultProps = {
  conditions: PropTypes.shape({
    conditions: PropTypes.objectOf(PropTypes.string),
  }),
}

RenderSelectCondition.propTypes = {
  condition: PropTypes.string.isRequired,
  conditions: PropTypes.shape({
    conditions: PropTypes.objectOf(PropTypes.string),
  }),
  selectOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
  })).isRequired,
}

export default RenderSelectCondition
