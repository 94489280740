import React from 'react'
import PropTypes from 'prop-types'
import { ExpansionPanel, Grid } from 'lq-ui'
import styles from './ProviderDetailsStyles'

const ProviderDetails = ({
  children,
  editing,
  showDescription,
  title,
}) => {
  const classes = styles()
  return (
    !showDescription && (
      <ExpansionPanel
        className={classes.expansion}
        expansionIconColor="#085986"
        headerTextColor="#085986"
        title={title}
        editing={editing}
      >
        <Grid type="container" className={classes.root} spacing={2}>
          { children }
        </Grid>
      </ExpansionPanel>
    )
  )
}

ProviderDetails.defaultProps = {
  editing: false,
  showDescription: false,
}

ProviderDetails.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  editing: PropTypes.bool,
  showDescription: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

export default ProviderDetails
