import React from 'react'
import { useJss } from 'lq-ui'
import LoadingSpinner from 'components/LoadingSpinnerComponent/LoadingSpinner'

const LoadingSpinnerView = (color, size, className, style) => {
  const useStyles = useJss({
    LoadingSpinnerView: {
      textAlign: 'center',
      height: '100%',
      width: '100%',
      top: '50%',
    },
  })
  const classes = useStyles()

  const loadingSpinner = LoadingSpinner(color, size, className, style)
  return (
    <div className={classes.LoadingSpinnerView}>
      {loadingSpinner}
    </div>
  )
}

LoadingSpinnerView.defaultProps = {
  color: '#18968d',
  size: 80,
  className: '',
  style: {},
}

export default LoadingSpinnerView
