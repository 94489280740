import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    button: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginLeft: '1rem',
      marginRight: '1rem',
    },
    buttonWidth: {
      width: '100%',
    },
  })
  const classes = useStyles()
  return classes
}
