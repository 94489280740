import React from 'react'
import PropTypes from 'prop-types'
import {
  AppBar,
  Button,
  Heading,
  useAuth,
  hooks,
} from 'lq-ui'
import pages from 'pages/routes.json'
import HomeLogo from 'assets/ditoLogoHome.png'
import styles from './TopNavigationStyles'

const TopNavigation = ({ title }) => {
  const classes = styles()
  const router = hooks.router.useRouter()
  const auth = useAuth()
  const signoutLabel = hooks.i18n.useMessage('signout.label')
  const signout = () => {
    auth.signout()
    router.push(pages.root)
  }
  return (
    <AppBar position="relative" className={classes.container}>
      <Button
        variant="default"
        onClick={() => router.push(pages.root)}
      >
        <img src={HomeLogo} alt="Home logo" className={classes.homeLogo} />
      </Button>
      <Heading level="h6" color="#fff" className={classes.preWrap}>
        {title}
      </Heading>
      <div className={classes.grow} />
      {
        auth.user
        && (
          <Button
            variant="default"
            onClick={() => (signout())}
            className={classes.button}
          >
            <span className={classes.logoutLabel}>{signoutLabel}</span>
            <i className={`fas fa-sign-out-alt ${classes.logoutIcon}`} />
          </Button>
        )
      }
    </AppBar>
  )
}

TopNavigation.defaultProps = {
  title: '',
}

TopNavigation.propTypes = {
  title: PropTypes.string,
}

export default TopNavigation
