import React from 'react'
import { hooks } from 'lq-ui'
import Layout from 'components/Layout'
import Providers from 'components/Providers'

const ProviderPage = () => (

  <Layout
    ProviderPage
    bottomtitle={hooks.i18n.useMessage('landing.imprint')}
    toptitle={hooks.i18n.useMessage('providers.toptitle')}
  >
    <Providers />
  </Layout>
)

export default ProviderPage
