import React from 'react'
import { TextField } from 'lq-ui'
import PropTypes from 'prop-types'

const ProviderAddress = ({
  editing, address: {
    value, onChange, onBlur, onKeyDown,
  }, label,
}) => (
  editing ? (
    <TextField
      value={value}
      label={label}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    />
  ) : (
    <div style={{ wordBreak: 'break-word', color: '#0f5886' }}>
      <b>{label}</b>
      <span>
        {value}
      </span>
    </div>
  )
)

ProviderAddress.defaultProps = {
  editing: false,
}

ProviderAddress.propTypes = {
  address: PropTypes.shape({
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    value: PropTypes.string.isRequired,
  }).isRequired,
  editing: PropTypes.bool,
  label: PropTypes.string.isRequired,
}

export default ProviderAddress
