import React from 'react'
import PropTypes from 'prop-types'
import DownloadCard from 'components/DownloadCard'
import { Grid } from 'lq-ui'
import styles from './DocumentsStyles'

const Documents = ({ documentList }) => {
  const classes = styles()
  return (
    <Grid type="container" className={classes.top}>
      { documentList.map((item) => (
        <Grid type="item" xs={12} sm={8} md={6} lg={3}>
          <DownloadCard
            key={window.crypto.getRandomValues(new Uint32Array(8))[0]}
            title={item.title}
            url={item.url}
            content={item.content}
          />
        </Grid>
      )) }
    </Grid>
  )
}

Documents.propTypes = {
  documentList: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired).isRequired,
}

export default Documents
