import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    top: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingBottom: '1rem',
      height: 'calc(100% - 65px)',
      overflowY: 'scroll',
    },
  })
  return useStyles()
}
