import React from 'react'
import PropTypes from 'prop-types'
import { hooks, Grid, Button } from 'lq-ui'
import styles from './ProviderMenuStyles'

const ProviderMenu = ({ providerMenu }) => {
  const router = hooks.router.useRouter()
  const classes = styles()
  const hoverButton = (item) => (
    router.query.type === item.route.slice(item.route.lastIndexOf('=') + 1)
      ? classes.chosenItem : classes.menuitem
  )
  return (
    <Grid
      alignItems="center"
      type="container"
      flexDirection="row"
      justifyContent="flex-start"
    >
      {providerMenu.filter((item) => item !== 'all').map((item) => (
        <Grid
          type="item"
          className={`${hoverButton(item)} ${classes.menu}`}
          key={item.title}
          xs={6}
          sm={4}
          lg={12}
        >
          <Button
            className={hoverButton(item)}
            fullWidth
            onClick={() => router.push(item.route)}
          >
            {item.title}
          </Button>
        </Grid>
      ))}
    </Grid>
  )
}

ProviderMenu.propTypes = {
  providerMenu: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ProviderMenu
