import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import {
  Grid, Card, useAuth, Modal, Icon, Button, hooks,
} from 'lq-ui'
// import MeetingForm from 'components/Meetings/MeetingForm'
import Meetingiew from 'components/Meetings/MeetingView'
import MeetingsActionButtons from 'components/Meetings/MeetingActionButtons'
import MeetingForm from 'components/Meetings/MeetingForm'
import {
  deleteMeeting, acceptProposal, rejectProposal, removeProposal,
} from 'redux/actions'
import styles from './styles'
// import MeetingCardPrint from './MeetingCardPrint'

const MeetingCard = ({
  meeting, isSideBar, isProposal, print,
}) => {
  const classes = styles()
  const dispatch = useDispatch()
  const { user } = useAuth()
  const [editing, setEditing] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false)
  const toggleEditing = (value) => setEditing(value)
  const handleDelete = (id) => dispatch(deleteMeeting(id))
  const handleAcceptProposal = (id) => dispatch(acceptProposal(id))
  const handleRejectProposal = (id) => dispatch(rejectProposal(id))
  const handleRemoveProposal = (id) => dispatch(removeProposal(id))


  return (
    <Grid
      type="item"
      xs={12}
      sm={isSideBar ? 12 : 6}
      md={isSideBar ? 12 : 4}
      lg={isSideBar ? 12 : 4}
      xl={isSideBar ? 12 : 3}
    >
      <Card>
        {!editing && !print
            && (
            <MeetingsActionButtons
              value
              user={user}
              isProposal={isProposal}
              onChange={() => toggleEditing(true)}
              handleDelete={() => setOpenModal(true)}
              handleAcceptProposal={() => handleAcceptProposal(meeting.id)}
              handleRejectProposal={() => handleRejectProposal(meeting.id)}
            />
            )}
        {editing
          ? (
            <MeetingForm
              meeting={meeting}
              user={user}
              isProposal={isProposal}
              toggleEditing={toggleEditing}
              editing={editing}
            />
          ) : <Meetingiew meeting={meeting} isProposal={isProposal} />}
      </Card>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div className={classes.modal}>
          <div className={classes.modalContent}>
            <div id="simple-modal-description">
              Sie wollen Sprachgelegenheiten
              {' '}
              <b>{meeting.name}</b>
              {' '}
              löschen.
            </div>
          </div>
          <div className={classes.modalFooter}>
            <Button
              onClick={() => {
                if (isProposal) { handleRemoveProposal(meeting.id) } else { handleDelete(meeting.id) }
              }}
              className={classes.confirmButton}
            >
              <Icon iconName="Check" color="#fff" className={classes.buttonIcon} />
              <span style={{ marginLeft: '5px' }}>{hooks.i18n.useMessage('general.confirm')}</span>
            </Button>
            <Button
              onClick={() => setOpenModal(false)}
              className={classes.cancelButton}
            >
              <Icon iconName="Clear" color="#fff" className={classes.buttonIcon} />
              <span style={{ marginLeft: '5px' }}>{hooks.i18n.useMessage('general.cancel')}</span>
            </Button>
          </div>
        </div>
      </Modal>
    </Grid>
  )
}

MeetingCard.defaultProps = {
  isSideBar: false,
  print: false,
  // isEditor: false,
  isProposal: false,
}

MeetingCard.propTypes = {
  // isEditor: PropTypes.bool,
  isSideBar: PropTypes.bool,
  isProposal: PropTypes.bool,
  print: PropTypes.bool,
  meeting: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    map: PropTypes.string,
  }).isRequired,
  // showDescription: PropTypes.bool.isRequired,
}

export default MeetingCard
