import React from 'react'
import LandingHeader from 'components/LandingHeader'
import Layout from 'components/Layout'
import BubbleGrid from 'components/BubbleGrid'
import BubbleDito from 'components/BubbleDito'
import { hooks } from 'lq-ui'
import routes from 'pages/routes.json'

const LandingPage = () => {
  const { i18n: { useMessage } } = hooks
  const bubbleList = [
    {
      color: '#209BCD',
      link: `${routes.graph}`,
      icon: 'link',
      label: useMessage('landing.bubble.graph'),
      size: 160,
      email: false,
    },
    {
      color: '#1589BA',
      link: `${routes.providers.root}`,
      icon: 'briefcase',
      label: useMessage('landing.bubble.providers'),
      size: 160,
      email: false,
    },
    {
      color: '#1478A7',
      link: `${routes.documents}`,
      icon: 'cloud-download-alt',
      label: useMessage('landing.bubble.download'),
      size: 160,
      email: false,
    },
    {
      color: '#085986',
      link: `${routes.meetings}`,
      icon: 'comments',
      label: useMessage('landing.bubble.meetings'),
      size: 160,
      email: false,
    },
    {
      color: '#098880',
      link: `${process.env.REACT_APP_FEEDBACK_MAIL}`,
      icon: 'envelope',
      label: useMessage('landing.bubble.feedback'),
      size: 125,
      email: true,
    },
  ]

  return (
    <Layout
      landingPage
      toptitle={useMessage('graph.toptitle')}
      bottomtitle={useMessage('landing.imprint')}
      showSkyline
    >
      <div style={{ height: '100%', overflowY: 'scroll' }}>
        <LandingHeader mainTitle={useMessage('landing.heading.main')} title={useMessage('landing.heading.title1')} title2={useMessage('landing.heading.title2')} />
        <BubbleGrid leftComponent={<BubbleDito />} bubbleList={bubbleList} />
      </div>
    </Layout>
  )
}

export default LandingPage
