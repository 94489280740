import React from 'react'
import PropTypes from 'prop-types'
import {
  PrintView, hooks, PrintButton, Grid, useAuth,
} from 'lq-ui'
import { useParams } from 'react-router-dom'
import MeetingsGrid from 'components/Meetings/MeetingsGrid'
import { resolveParams } from 'services/urlParams'
import MeetingsActionButtons from 'components/Meetings/MeetingActionButtons'
import MeetingForm from 'components/Meetings/MeetingForm'
import MeetingsInfo from 'components/Meetings/MeetingsInfo'
import styles from './MeetingsListStyles'

const sortMeetings = (meetings) => {
  const filteredMeetings = meetings.filter((p) => p.district !== '')
  return filteredMeetings.sort((a, b) => {
    const nameA = a.name.toLowerCase()
    const nameB = b.name.toLowerCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })
}

const MeetingsList = ({ meetings, isProposal }) => {
  const classes = styles()
  const { user } = useAuth()
  const [print, setPrint] = hooks.window.usePrint()
  const [newMeeting, setNewMeeting] = React.useState(false)
  const { district } = useParams()
  const meetingsList = meetings.filter((m) => m.district.toLowerCase() === resolveParams(district))
  const toggleNewMeeting = (value) => setNewMeeting(value)
  return (
    <>
      {(!newMeeting && !isProposal) && (
      <Grid
        type="item"
        className={classes.actionArea}
      >
        <Grid
          type="container"
          justifyContent="space-between"
          spacing={1}
          // style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid
            type="item"
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={3}
          >
            <MeetingsActionButtons
              value={newMeeting}
              onChange={() => toggleNewMeeting(!newMeeting)}
              isProposal={isProposal}
              user={user}
            />
          </Grid>
          <PrintButton handlePrint={setPrint} />
        </Grid>
      </Grid>
      )}

      <Grid
        type="container"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        {newMeeting
        && (
          <Grid
            type="item"
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={3}
          >
            <MeetingForm
              isNew={newMeeting}
              isProposal={isProposal}
              editing={newMeeting}
              toggleNewMeeting={toggleNewMeeting}
              user={user}
            />
          </Grid>
        )}
        {meetingsList.length
          ? <MeetingsGrid items={sortMeetings(meetingsList)} isEditor fixedWidth isProposal={isProposal} />
          : newMeeting
            ? <div />
            : <MeetingsInfo proposalsRoute={isProposal} proposals={meetings.length} />}
      </Grid>
      { print && (
      <PrintView>
        <MeetingsGrid
          items={sortMeetings(meetingsList)}
          showDescription
          isPrinting={print}
          isProposal={isProposal}
        />
      </PrintView>
      )}
    </>
  )
}
MeetingsList.defaultProps = {
  isProposal: false,
}

MeetingsList.propTypes = {
  meetings: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    map: PropTypes.string,
    provider: PropTypes.string.isRequired,
    contactName: PropTypes.string.isRequired,
    serviceHours: PropTypes.string.isRequired,
  })).isRequired,
  isProposal: PropTypes.bool,
}

export default MeetingsList
