import React from 'react'
import {
  Heading,
  hooks,
  Button,
} from 'lq-ui'
import NodeInfoView from 'components/NodeInfoView'
import useNodeInfo from 'services/useNodeInfo'
import { selectNode } from 'redux/actions'
import { useDispatch } from 'react-redux'
import styles from './NodeInformationStyles'


const NodeInfo = () => {
  const [node] = useNodeInfo()
  const classes = styles()
  const emptyNode = hooks.i18n.useMessage('graph.config.empty')
  const dispatch = useDispatch()
  const handleClick = () => dispatch(selectNode(0))
  return (
    <div className={classes.paper}>
      <Button className={classes.button} onClick={handleClick}>
        Zurück zur Person
      </Button>
      {node ? <NodeInfoView toggleExtended /> : <Heading level="h6" color="#760074">{emptyNode}</Heading>}
    </div>
  )
}

export default NodeInfo
