import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: '8px',
    },
    submit: {
      margin: '24px 0px 16px',
      backgroundColor: '#760074',
      color: '#fff',
    },
    validation: {
      color: 'red',
      fontSize: '10px',
      marginLeft: '20px',
    },
  })
  return useStyles()
}
