import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    menuitem: {
      backgroundColor: '#fff',
      textTransform: 'none',
      justifyContent: 'flex-start',
      fontSize: '1rem',
      // '&:hover': {
      //   // color: '#fff',
      //   backgroundColor: '#085986',
      // },
    },
    chosenItem: {
      color: '#fff',
      backgroundColor: '#085986',
      textTransform: 'none',
      justifyContent: 'flex-start',
      fontSize: '1rem',
      // '&:hover': {
      //   backgroundColor: '#085986',
      // },
    },
    first: {
      borderTopRightRadius: 4,
      borderTopLeftRadius: 4,
    },
    last: {
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
    },
    menu: {
      padding: 10,
      cursor: 'pointer',
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
  })
  return useStyles()
}
