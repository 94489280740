import React from 'react'
import { Heading, Card, hooks } from 'lq-ui'
import NodeInfoExtended from 'components/NodeInfoExtended'
import NodeInfoBox from 'components/NodeInfoBox'
import useNodeInfo from 'services/useNodeInfo'

const NodeInfoView = () => {
  const [node,, nodeProviders] = useNodeInfo()
  const emptyNode = hooks.i18n.useMessage('graph.config.empty')
  return (
    (node && node.id !== 0 && node.id !== -1)
      ? (
        <Card>
          <Card.Header
            textColor="#085986"
            title={(
              <div>
                {node.name}
              </div>
        )}
          />
          <Card.Content>
            <NodeInfoBox node={node} />
            <NodeInfoExtended nodeDescription={node.description} nodeProviders={nodeProviders} />
          </Card.Content>
        </Card>
      )
      : <Heading level="h6" color="#760074">{emptyNode}</Heading>
  )
}

export default NodeInfoView
