/* eslint-disable import/prefer-default-export */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadGraph } from 'redux/actions'
import { hooks } from 'lq-ui'

export default () => {
  let { providers } = useSelector((state) => state.graph)
  const { status } = useSelector((state) => state.appState)
  const dispatch = useDispatch()
  const { type } = hooks.router.useRouter().query

  providers = providers.filter((p) => p.id !== '') // Can be removed when all providers have ids
  providers = type === 'all' ? providers : providers.filter((p) => p.type === type)

  React.useEffect(() => {
    if (!status) { // if page reloaded with type, the filter is not starting
      dispatch(loadGraph())
    }
  }, [status, dispatch])

  return providers
}
