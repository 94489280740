import React from 'react'
import ProviderContactDetail from 'components/ProviderContactDetail'

const ProviderContacts = ({ contacts, editing }) => (Object.keys(contacts)
  .map((t) => (
    <ProviderContactDetail
      key={t}
      content={contacts[t]}
      type={t}
      isLink={t === 'url'}
      editing={editing}
    />
  )))
export default ProviderContacts
