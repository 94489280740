import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    container: {
      marginBottom: '10rem',
    },
    grid: {
      display: 'grid',
      gridTemplateRows: '160px auto',
      gridGap: '1rem',
      alignItems: 'start',
      justifyContent: 'center',
    },
    bubblesGrid: {
      display: 'grid',
      gridTemplateRows: 'repeat(5, auto)',
      alignItems: 'center',
      justifyContent: 'center',
      gridGap: '.5rem',
    },
    bubblesGridItem: {
      textAlign: 'center',
    },
    '@media (min-width: 813px)': {
      container: {
        marginBottom: '3rem',
      },
      grid: {
        gridTemplateColumns: '1fr 3fr',
        gridTemplateRows: 'none',
        gridGap: '1rem',
        alignItems: 'center',
        justifyContent: 'start',
        maxWidth: '100%',
        overflowX: 'hidden',
        padding: '5rem .5rem',
      },
      logo: {
        // padding: '0.5rem 0 3rem',
      },
      bubblesGrid: {
        gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 1fr))',
        gridTemplateRows: 'none',
        gridGap: '.5rem',
        // padding: '0.5rem 0 3rem',
      },
    },
  })
  return useStyles()
}
