import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '2rem',
      marginBottom: '2rem',
    },
    headerText: {
      marginRight: '.5rem',
    },
    span: { color: '#085986' },
  })
  return useStyles()
}
