const sortProviders = (providers) => {
  const filteredProviders = providers.filter((p) => p.type !== '')
  return filteredProviders.sort((a, b) => {
    const nameA = a.name.toLowerCase()
    const nameB = b.name.toLowerCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })
}

export default sortProviders

export const sortSelectedProviders = (allProviders, providerSelections) => {
  // Sorting of providers
  const selected = allProviders.filter((p) => providerSelections.includes(p.id))
    .sort((a, b) => a.name.localeCompare(b.name))
  const nonSelected = allProviders.filter((p) => !providerSelections.includes(p.id))
    .sort((a, b) => a.name.localeCompare(b.name))
  const sortedProviders = selected.concat(nonSelected)
  return sortedProviders
}
