import React from 'react'
import PropTypes from 'prop-types'
import { PrintButton } from 'lq-ui'

const ProviderPrintButton = ({ handlePrint, noButton }) => (noButton ? null : <PrintButton handlePrint={handlePrint} />)

ProviderPrintButton.defaultProps = {
  handlePrint: () => null,
  noButton: false,
}

ProviderPrintButton.propTypes = {
  handlePrint: PropTypes.func,
  noButton: PropTypes.bool,
}

export default ProviderPrintButton
