import React from 'react'
import { Heading } from 'lq-ui'
import PropTypes from 'prop-types'
import styles from './LandingHeaderStyles'

const LandingHeader = ({ mainTitle, title, title2 }) => {
  const classes = styles()
  return (
    <div className={classes.landingHeader}>
      <Heading
        color="#085986"
        level="h3"
        className={classes.heading}
      >
        <b className={classes.mainLabel}>
          {mainTitle}
        </b>
      </Heading>
      <Heading
        color="#085986"
        level="h5"
        className={classes.heading}
      >
        {title}
        <br/>
        {title2}
      </Heading>
    </div>
  )
}

LandingHeader.propTypes = {
  mainTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default LandingHeader
