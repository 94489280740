import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { loadGraph } from 'redux/actions'
import LoadingSpinnerView from 'components/LoadingSpinnerComponent'
import {
  computeCriteriaValues, computeGraphByConditions, nodeSelector, nodeConnectedGraphSelector,
  graphSelector, editorNodeSelector,
} from 'redux/selectors'
import Graph from './Graph'

const GraphContainer = ({
  className, isEditor, id, showLoading,
}) => {
  const { appState: { status, persona } } = useSelector((state) => state)
  const selectedNode = useSelector(nodeSelector)
  const criteriaValues = useSelector(computeCriteriaValues)
  const dispatch = useDispatch()
  // constains filtered nodes..
  const graph = useSelector(computeGraphByConditions)
  const editorGraph = useSelector(nodeConnectedGraphSelector)
  const originalGraph = useSelector(graphSelector)
  const editorSelectedNode = useSelector(editorNodeSelector)
  const node = originalGraph.nodes.find((n) => n.id === editorSelectedNode)

  const test = {
    ...editorGraph,
    nodes: editorGraph.nodes.find((n) => n.id)
      ? editorGraph.nodes
      : [...editorGraph.nodes, node],
  }


  React.useEffect(() => {
    if (!status) dispatch(loadGraph())
  }, [status, dispatch, criteriaValues])

  return (
    <>
      { status === 2
        ? (
          <Graph
            nodesData={isEditor ? test.nodes : graph.nodes}
            edgesData={isEditor ? test.edges : graph.edges}
            className={className}
            criteria={persona}
            id={id}
            selectedNode={selectedNode}
            isEditor
          />
        )
        : (showLoading && <LoadingSpinnerView />)}
    </>
  )
}

GraphContainer.defaultProps = {
  className: '',
  isEditor: false,
  id: 'network',
  showLoading: true,
}

GraphContainer.propTypes = {
  className: PropTypes.string,
  isEditor: PropTypes.bool,
  id: PropTypes.string,
  showLoading: PropTypes.bool,
}

export default GraphContainer
