
import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    paper: {
      marginTop: '64px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: '8px',
      backgroundColor: '#f2e9f2',
    },
    container: {
      maxWidth: '60% !important',
      marginLeft: '30% !important',
      marginRight: '30% !important',
      marginTop: '10% !important',
    },
    signInIcon: {
      fontSize: '1.875rem',
      padding: '15px',
      borderRadius: '50%',
      background: '#085986',
      color: '#fff',
    },
  })
  const classes = useStyles()
  return classes
}
