/* eslint-disable global-require */
import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import reducers from 'redux/reducers'
import logger from 'redux-logger'

let middlewares = []

if (process.env.NODE_ENV !== 'production') {
  middlewares = [logger]
}

const store = createStore(reducers, applyMiddleware(thunk, ...middlewares))

const ReduxProvider = ({ children }) => (
  <Provider store={store}>
    { children }
  </Provider>
)

ReduxProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ReduxProvider
