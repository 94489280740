import { createSelector } from 'reselect'

export const graphSelector = createSelector(
  (state) => state.graph.nodes,
  (state) => state.graph.edges,
  (nodes, edges) => ({ nodes, edges }),
)

export const computeGraph = createSelector(
  (state) => state.graph.nodes,
  (state) => state.graph.edges,
  (state) => state.appState.persona,
  (nodes, edges, conditions) => {
    const checkPersona = (edge) => Object.entries(conditions)
      .reduce((p, [key, value]) => p && (value === '' || !edge[key] || edge[key] === value), true)

    const outbound = (set) => edges.filter((e) => set.find((r) => e.from === r.to && checkPersona(e)))
    let count = 0
    let reachable = edges.filter((e) => !e.from && checkPersona(e))
    while (reachable.length !== count) {
      count = reachable.length
      reachable = Array.from(new Set(reachable.concat(outbound(reachable))))
    }
    return ({
      edges: reachable.filter((e) => e.to).map((edge) => ({ ...edge })),
      nodes: nodes.filter((n) => reachable.find((e) => e.to === n.id || e.from === n.id)),
    })
  },
)

export const computeGraphByConditions = createSelector(
  computeGraph,
  (state) => state.appState.conditions,
  (graph, conditions) => {
    const checkEdge = (e) => Object.entries(e)
      .reduce((p, [k, v]) => p
      && (['id', 'from', 'to'].includes(k) || v === '' || conditions[k] === v), true)

    const inbound = (set) => graph.edges.filter((e) => set.find((r) => e.from === r.to && checkEdge(e)))
    let count = 0
    let paths = graph.edges.filter((e) => !e.from && checkEdge(e))
    while (paths.length !== count) {
      count = paths.length
      paths = Array.from(new Set(paths.concat(inbound(paths))))
    }

    const edges = paths
    const nodes = graph.nodes.filter((n) => edges.find((e) => e.to === n.id))

    return ({
      edges: Object.keys(conditions).length
        ? edges
        : [],
      nodes: Object.keys(conditions).length
        ? [...nodes, ...graph.nodes.filter((n) => n.id === 0)]
        : graph.nodes.filter((n) => n.id === 0),
    })
  },
)
