import React from 'react'
import PropTypes from 'prop-types'
import { Heading, useJss } from 'lq-ui'
import ConditionComponent from 'components/ConditionComponent'
import { useSelector } from 'react-redux'

const ConditionsGroup = ({ conditionsLabels, title }) => {
  const useStyles = useJss({
    conditionsGroup: {
      marginTop: '1rem',
    },
  })

  const { conditions } = useSelector((state) => state.appState)

  const conditionsSet = conditionsLabels.map((label) => ({ label, value: conditions[label] }))
  const comditionsWithValue = conditionsSet.filter((c) => c.value).map((c) => c.label)
  const conditionsWithoutValues = conditionsSet.filter((c) => !c.value).map((c) => c.label)

  const classes = useStyles()
  return (
    <div className={classes.conditionsGroup}>
      {!!conditionsLabels.length && (
        <>
          <Heading level="h6" color="#085986">{ title }</Heading>
          {[...comditionsWithValue, ...conditionsWithoutValues]
            .map((c) => <ConditionComponent condition={c} key={c} />)}
        </>
      )}
    </div>
  )
}

ConditionsGroup.propTypes = {
  conditionsLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
}

export default ConditionsGroup
