import React from 'react'
import { useRequireAuth } from 'lq-ui'
import Layout from 'components/Layout'
import Editor from 'components/GraphEditor/Editor'
import LoadingSpinnerView from 'components/LoadingSpinnerComponent/LoadingSpinnerView'

const EditorPage = () => {
  const auth = useRequireAuth()
  if (!auth.user) {
    return <LoadingSpinnerView />
  }

  return (
    <main>
      <Layout
        GraphPage
        bottomtitle="Impressum"
        toptitle="Editormodus für Bildungsketten Bielefeld"
      >
        <Editor />
      </Layout>
    </main>
  )
}

export default EditorPage
