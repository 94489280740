import React from 'react'
import PropTypes from 'prop-types'
import ProviderAddress from 'components/ProviderAddress'
import ProviderContent from 'components/ProviderContent'
import ProviderContentHeader from 'components/ProviderContentHeader'
import ProviderName from 'components/ProviderName'
import ProviderView from 'components/ProviderView'
import ProviderMap from 'components/ProviderMap'
import ProviderContacts from 'components/ProviderContacts'
import { hooks, PrintView } from 'lq-ui'

const ProviderCardPrint = ({
  print, provider,
}) => (
  <PrintView print={print}>
    <ProviderView>
      <ProviderName
        editing={false}
        noButton
        title={{ value: provider.name }}
        label={hooks.i18n.useMessage('providers.card.name')}
      />
      <ProviderContent>
        <ProviderContentHeader
          showDescription
          shortDescription={provider.shortDescription}
          description={provider.description}
        />
        <ProviderContacts contacts={{
          email: { value: provider.email },
          phone: { value: provider.phone },
          url: { value: provider.url },
        }}
        />
        <ProviderAddress
          editing={false}
          printing
          address={{ value: provider.address }}
          label={hooks.i18n.useMessage('providers.card.address')}
        />
      </ProviderContent>
      <ProviderMap address={{ value: provider.address, map: provider.map }} />
    </ProviderView>
  </PrintView>
)

ProviderCardPrint.defaultProps = {
  print: false,
  provider: null,
}

ProviderCardPrint.propTypes = {
  print: PropTypes.bool,
  provider: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    map: PropTypes.string,
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
}

export default ProviderCardPrint
