import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'lq-ui'
import styles from './ProviderContentStyles'

const ProviderContent = ({ children }) => {
  const classes = styles()

  return (
    <div className={classes.adjust}>
      <Card.Content>
        { children }
      </Card.Content>
    </div>
  )
}

ProviderContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]).isRequired,
}

export default ProviderContent
