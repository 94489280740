import React from 'react'
import PropTypes from 'prop-types'
import { Badge, Button, hooks } from 'lq-ui'
import BubbleContent from 'components/BubbleContent'
import styles from './BubbleStyles'

const Bubble = ({
  color, link, label, icon, size, email, badge, borderColor,
}) => {
  const classes = styles(size, badge && badge.color, borderColor)
  const router = hooks.router.useRouter()

  return (
    <Button
      className={classes.bubbleFab}
      onClick={email ? () => window.open(`mailto:${link}`) : () => router.push(link)}
      variant="fab"
    >
      {
          badge ? (
            <Badge
              badgeContent={badge.label}
              color="primary"
              className={classes.badge}
            >
              <BubbleContent
                badge={badge}
                color={color}
                icon={icon}
                label={label}
                size={size}
              />
            </Badge>
          ) : (
            <BubbleContent
              badge={badge}
              color={color}
              icon={icon}
              label={label}
              size={size}
            />
          )
        }
    </Button>
  )
}

Bubble.defaultProps = {
  badge: null,
  borderColor: '#fff',
  email: null,
}

Bubble.propTypes = {
  badge: PropTypes.shape({
    color: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  borderColor: PropTypes.string,
  color: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  icon: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  email: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default Bubble
