import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    ditoLogo: {
      minWidth: '225px',
      maxWidth: '25vw',
      minHeight: '150px',
      maxHeight: '25vw',
    },
  })
  const classes = useStyles()
  return classes
}
