import React from 'react'
import PropTypes from 'prop-types'
import { Card, Icon } from 'lq-ui'
import styles from './DownloadCardStyles'

const DownloadCard = ({ title, url, content }) => {
  const classes = styles()
  return (
    <Card
      className={classes.card}
      onClick={() => { window.open(url, '_blank') }}
    >
      <Card.Header
        textColor="#085986"
        className={classes.header}
        title={(
          <div>
            <p>{title}</p>
            <Icon color="#760074" iconName="CloudDownload" />
          </div>
        )}
      />
      <Card.Content>
        <span className={classes.cardContent}>{content}</span>
      </Card.Content>
    </Card>
  )
}

DownloadCard.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

export default DownloadCard
