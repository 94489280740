import React from 'react'
import PropTypes from 'prop-types'
import { hooks } from 'lq-ui'
import ProviderMap from 'components/ProviderMap'
import useAddress from 'components/Meetings/MeetingForm/useAddress'

const RenderMeetingViewElement = ({ label, meeting }) => {
  const address = useAddress(meeting.address, meeting.map)

  return (
    <>
      <div style={{ wordBreak: 'break-word', color: '#0f5886', padding: '.5rem 0' }}>
        <b>
          {hooks.i18n.useMessage(`meeting.card.${label}`)}
          :
          {' '}
        </b>
        <span>
          {label === 'address' ? address.value : meeting[label]}
        </span>
      </div>
      {label === 'address' && <ProviderMap address={address} editing={false} />}
    </>
  )
}

RenderMeetingViewElement.propTypes = {
  meeting: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    serviceHours: PropTypes.string,
    contactName: PropTypes.string,
    shortDescription: PropTypes.string,
    provider: PropTypes.string,
    district: PropTypes.string,
    address: PropTypes.string,
    map: PropTypes.string,
  }).isRequired,
  label: PropTypes.string.isRequired,
}

export default RenderMeetingViewElement
