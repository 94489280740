import React from 'react'
import PropTypes from 'prop-types'
import ProviderAddress from 'components/ProviderAddress'
import ProviderContent from 'components/ProviderContent'
import ProviderContentHeader from 'components/ProviderContentHeader'
import ProviderName from 'components/ProviderName'
import ProviderView from 'components/ProviderView'
import ProviderMap from 'components/ProviderMap'
import ProviderEditButton from 'components/ProviderEditButton'
import useForm from 'components/ProviderForm/useForm'
import ProviderDetails from 'components/ProviderDetails'
import ProviderDescription from 'components/ProviderDescription'
import ProviderContacts from 'components/ProviderContacts'
import ProviderSelectType from 'components/ProviderSelectType'
import { hooks } from 'lq-ui'
import { editorNodeSelector } from 'redux/selectors'
import { useSelector } from 'react-redux'

const ProviderForm = ({
  provider, isEditor, isNew, noButton, setPrint, showDescription, showType, editorPage,
  // print,
}) => {
  const editorSelectedNode = useSelector(editorNodeSelector)

  const {
    editing: { value, onChange },
    address, name, email, phone, url, shortDescription, description, type, handleUpdate, handleDelete,
  } = useForm(provider || { name: '' }, editorPage && editorSelectedNode)

  return (
    <ProviderView>
      {isEditor && (
      <ProviderEditButton
        isNew={!!isNew}
        value={value || !!isNew}
        onChange={onChange}
        handleUpdate={handleUpdate}
        handleDelete={() => handleDelete(provider.id)}
      />
      )}
      <ProviderName
        editing={value || !!isNew}
        noButton={noButton && isEditor}
        handlePrint={setPrint}
        isEditor={isEditor}
        title={name}
        label={hooks.i18n.useMessage('providers.card.name')}
        onChangeTitle={name.onChange}
      />
      <ProviderContent>
        <ProviderContentHeader
          showDescription={showDescription}
          shortDescription={shortDescription.value}
          description={description.value}
        />
        <ProviderContacts editing={value || !!isNew} contacts={{ email, phone, url }} />

        {isEditor && (
        <ProviderAddress
          editing={!!isNew || value}
          address={address}
          label={hooks.i18n.useMessage('providers.card.address')}
        />
        )}
      </ProviderContent>
      <ProviderMap address={address} editing={value || !!isNew} />
      <ProviderDetails
        showDescription={showDescription}
        editing={value || !!isNew}
        title={hooks.i18n.useMessage('providers.card.details')}
      >
        <ProviderDescription
          editing={value || !!isNew}
          value={shortDescription.value}
          label={hooks.i18n.useMessage('providers.card.shortDescription')}
          onChange={shortDescription.onChange}
        />
        { (provider?.description || isEditor)
        && (
        <ProviderDescription
          editing={value || !!isNew}
          value={description.value}
          label={hooks.i18n.useMessage('providers.card.description')}
          onChange={description.onChange}
        />
        )}
        {!isEditor && (
        <ProviderAddress
          editing={value || !!isNew}
          address={address}
          label={hooks.i18n.useMessage('providers.card.address')}
        />
        )}
        { showType
        && (
        <ProviderSelectType
          editing={value || !!isNew}
          onChange={type.onChange}
          label={hooks.i18n.useMessage('provider.config.filter.type.label')}
          optionsLabel={hooks.i18n.useMessage('component.select.options')}
          value={type.value}
        />
        )}
      </ProviderDetails>
    </ProviderView>
  )
}

ProviderForm.defaultProps = {
  noButton: false,
  // print: false,
  provider: null,
  isEditor: false,
  isNew: false,
  setPrint: () => null,
  showType: false,
  editorPage: false,
}

ProviderForm.propTypes = {
  isEditor: PropTypes.bool,
  isNew: PropTypes.bool,
  noButton: PropTypes.bool,
  showType: PropTypes.bool,
  // print: PropTypes.bool,
  provider: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    map: PropTypes.string,
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  setPrint: PropTypes.func,
  showDescription: PropTypes.bool.isRequired,
  editorPage: PropTypes.bool,
}

export default ProviderForm
