import { useJss } from 'lq-ui'

export default (size, color, badgeColor) => {
  const bubbleSize = size
  const bubblePaddingBottom = bubbleSize / 10
  const bubbleIconSize = (bubbleSize / 2.5)

  const basicBubble = {
    width: bubbleSize,
    height: bubbleSize,
    paddingBottom: bubblePaddingBottom,
  }
  const useStyles = useJss({
    bubble: {
      ...basicBubble,
      backgroundColor: `${color} !important`,
    },
    badge: {
      '& span': {
        backgroundColor: badgeColor || '#760074',
        top: `${bubbleSize / 10}px`,
        fontSize: `${bubbleSize * 222.222} rem`,
        right: `${bubbleSize / 8}px`,
      },
    },
    bubbleContent: {
      textAlign: 'center',
    },
    bubbleIcon: {
      color: 'white',
      fontSize: bubbleIconSize,
      padding: `${bubbleSize / 25}px`,
    },
    bubbleLabel: {
      fontSize: `${bubbleSize / 160}rem`,
      textTransform: 'initial',
    },
  })
  return useStyles()
}
