import React from 'react'
import { hooks, Heading } from 'lq-ui'
import PropTypes from 'prop-types'
import MeetingsPic from 'assets/meetingsPage/meetings.png'
import styles from './styles'

const MeetingsInfo = ({ proposalsRoute, proposals }) => {
  const classes = styles()
  const isMobile = hooks.window.useIsMobile()
  const info = proposalsRoute
    ? proposals ? 'proposals.info' : 'noProposals'
    : 'meetings.info'
  return (
    isMobile
      ? (
        <div className={classes.mobile}>
          <Heading level="h4" color="#085986">
            <b>{hooks.i18n.useMessage(info)}</b>
          </Heading>
        </div>
      ) : (
        <div className={classes.info}>
          <Heading level="h6" color="#085986" className={classes.infoMessage}>
            <b>{hooks.i18n.useMessage(info)}</b>
          </Heading>
          <img className={classes.image} src={MeetingsPic} alt="Meeting Info Bild" />
        </div>
      )
  )
}

MeetingsInfo.propTypes = {
  proposalsRoute: PropTypes.bool.isRequired,
  proposals: PropTypes.number.isRequired,
}

export default MeetingsInfo
