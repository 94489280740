import React from 'react'
import PropTypes from 'prop-types'
import { hooks, Grid, Button } from 'lq-ui'
import { formParams, resolveParams } from 'services/urlParams'
import styles from './styles'

const Menu = ({ menuItems }) => {
  const router = hooks.router.useRouter()
  const classes = styles()
  const { match: { url, path }, pathname } = router
  const hoverButton = (route) => (
    resolveParams(pathname.substring(url.length + 1)) === route.toLowerCase()
      ? classes.chosenItem : classes.menuitem
  )
  const setRoute = (item) => (['proposals', 'meetings'].includes(item) ? `/${item}` : `${path}/${formParams(item)}`)

  return (
    <Grid
      alignItems="center"
      type="container"
      flexDirection="row"
      justifyContent="flex-start"
    >
      {menuItems.map((item) => (
        <Grid
          type="item"
          className={`${hoverButton(item.route)} ${classes.menu}`}
          key={item.label}
          xs={6}
          sm={4}
          lg={12}
        >
          <Button
            className={`${hoverButton(item.route)} ${classes.button}`}
            fullWidth
            onClick={() => router.push(setRoute(item.route))}
          >
            {item.label}
          </Button>
        </Grid>
      ))}
    </Grid>
  )
}

Menu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
}

export default Menu
