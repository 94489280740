import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    card: {
      margin: '1rem 2rem',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#d5d5d5',
      },
      height: '250px',
    },
    cardContent: {
      color: '#085986',
    },
    header: {
      minHeight: '125px',
      alignItems: 'end',
    },
  })
  return useStyles()
}
