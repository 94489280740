import React from 'react'
import { useDispatch } from 'react-redux'
import { updateProviderInformation, deleteProvider } from 'redux/actions'
import useAddress from './useAddress'

export default (provider, editorSelectedNode) => {
  const [name, setName] = React.useState(provider.name)
  const [editing, setEditing] = React.useState(false)
  const [shortDescription, setShortDescription] = React.useState(provider.shortDescription)
  const [description, setDescription] = React.useState(provider.description)
  const [email, setEmail] = React.useState(provider.email)
  const [phone, setPhone] = React.useState(provider.phone)
  const [url, setUrl] = React.useState(provider.url)
  const address = useAddress(provider.address, provider.map)
  const [type, setType] = React.useState(provider.type)
  const [serviceHours, setServiceHours] = React.useState(provider.serviceHours)
  const [contactName, setContactName] = React.useState(provider.contactName)
  const [providerName, setProviderName] = React.useState(provider.provider)
  const [district, setDistrict] = React.useState(provider.district)

  const handleTypeChange = (value) => setType(value)
  const handleShortDescription = (e) => setShortDescription(e.target.value)
  const handleDescription = (e) => setDescription(e.target.value)
  const handleNameChange = (e) => setName(e.target.value)
  const handleToggleEdit = (value) => setEditing(value)
  const handleChangeEmail = (e) => setEmail(e.target.value)
  const handleChangePhone = (e) => setPhone(e.target.value)
  const handleChangeUrl = (e) => setUrl(e.target.value)
  const handleChangeServiceHourse = (e) => setServiceHours(e.target.value)
  const handleChangeContactName = (e) => setContactName(e.target.value)
  const handleProviderNameChange = (e) => setProviderName(e.target.value)
  const handleDistrictChange = (e) => setDistrict(e.target.value)

  const dispatch = useDispatch()

  const handleUpdate = () => {
    const data = {
      ...provider,
      address: address.value,
      description,
      email,
      name,
      phone,
      shortDescription,
      type,
      url,
      map: address.map,
      serviceHours,
      contactName,
      providerName,
      district,
    }
    dispatch(updateProviderInformation(data, editorSelectedNode && editorSelectedNode))
  }

  const handleDelete = (providerId) => dispatch(deleteProvider(providerId))


  return {
    provider,
    handleUpdate,
    handleDelete,
    type: { value: type, onChange: handleTypeChange },
    address,
    editing: { value: editing, onChange: handleToggleEdit },
    email: { value: email, onChange: handleChangeEmail },
    name: { value: name, onChange: handleNameChange },
    phone: { value: phone, onChange: handleChangePhone },
    shortDescription: { value: shortDescription, onChange: handleShortDescription },
    description: { value: description, onChange: handleDescription },
    url: { value: url, onChange: handleChangeUrl },
    serviceHours: { value: serviceHours, onChange: handleChangeServiceHourse },
    contactName: { value: contactName, onChange: handleChangeContactName },
    providerName: { value: providerName, onChange: handleProviderNameChange },
    district: { value: district, onChange: handleDistrictChange },

  }
}
