import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectNode } from 'redux/actions'
import { graphSelector, editorNodeSelector } from 'redux/selectors'
import { Select, Paper } from 'lq-ui'
import styles from './NodeSelectStyles'

const sortByLabel = (a, b) => {
  if (a.label < b.label) return -1
  if (a.label > b.label) return 1
  return 0
}

const NodeSelect = () => {
  const dispatch = useDispatch()
  const graph = useSelector(graphSelector)
  const node = useSelector(editorNodeSelector)
  const nodesList = graph.nodes ? graph.nodes.map((n) => ({ ...n, value: n.id })).sort(sortByLabel) : []
  const classes = styles()

  const handleChange = (val) => {
    dispatch(selectNode(val))
  }

  return (
    <Paper className={classes.paper}>
      <Select
        required
        inputLabel="Bitte wählen Sie ein Element..."
        value={node}
        items={nodesList}
        textColor="#085986"
        onChange={(val) => handleChange(val)}
      />
    </Paper>
  )
}

export default NodeSelect
