import React from 'react'
import PropTypes from 'prop-types'
import Bubble from 'components/Bubble'
import styles from './BubbleGridStyles'

const BubbleGrid = ({
  bubbleList, leftComponent,
}) => {
  const classes = styles()
  return (
    <div className={classes.container}>
      <div className={classes.grid}>
        <div>
          { leftComponent }
        </div>
        <div className={classes.bubblesGrid}>
          { bubbleList.map((bubble) => (
            <div className={classes.bubblesGridItem} key={bubble.label}>
              <Bubble
                color={bubble.color}
                link={bubble.link}
                icon={bubble.icon}
                label={bubble.label}
                size={bubble.size}
                email={bubble.email}
                badge={bubble.badge}
                borderColor={bubble.borderColor}
              />
            </div>
          )) }
        </div>
      </div>
    </div>
  )
}

BubbleGrid.defaultProps = {
  leftComponent: null,
}

BubbleGrid.propTypes = {
  bubbleList: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    email: PropTypes.bool.isRequired,
    borderColor: PropTypes.string,
  })).isRequired,
  leftComponent: PropTypes.element,
}

export default BubbleGrid
