const initialState = {
  edges: [],
  nodes: [],
  providers: [],
  meetings: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GRAPH_LOAD_SUCCESS':
      return {
        ...state,
        ...action.payload,
      }
    case 'UPDATE_NODE_INFORMATION':
      return {
        ...state,
        nodes: state.nodes.map((n) => (n.id === action.payload.id ? action.payload : n)),
      }
    case 'UPDATE_PROVIDER_INFORMATION':
      return {
        ...state,
        providers: state.providers.map((p) => (p.id === action.payload.id ? action.payload : p)),
      }
    case 'UPDATE_MEETING_INFORMATION':
      return {
        ...state,
        meetings: state.meetings.map((m) => (m.id === action.payload.id ? action.payload : m)),
      }
    case 'DELETE_MEETING':
      return {
        ...state,
        meetings: state.meetings.filter((m) => (m.id !== action.payload)),
      }
    case 'UPDATE_EDGE_CONDITIONS':
      return {
        ...state,
        edges: state.edges.map((e) => (e.id === action.payload.id ? action.payload : e)),
      }

    default:
      return state
  }
}
