import React from 'react'
import Layout from 'components/Layout'
import Documents from 'components/Documents'
import { hooks } from 'lq-ui'

const DocumentsPage = () => {
  const domain = `${process.env.PUBLIC_URL}/download/`
  const { i18n: { useMessage } } = hooks
  const documentList = [
    {
      title: useMessage('documents.title.1'),
      url: `${domain}${useMessage('documents.file.1')}`,
      content: useMessage('documents.content.1'),
    },
    {
      title: useMessage('documents.title.2'),
      url: `${domain}${useMessage('documents.file.2')}`,
      content: useMessage('documents.content.2'),
    },
    {
      title: useMessage('documents.title.3'),
      url: `${domain}${useMessage('documents.file.3')}`,
      content: useMessage('documents.content.3'),
    },
    {
      title: useMessage('documents.title.4'),
      url: `${domain}${useMessage('documents.file.4')}`,
      content: useMessage('documents.content.4'),
    },
    {
      title: useMessage('documents.title.5'),
      url: `${domain}${useMessage('documents.file.5')}`,
      content: useMessage('documents.content.5'),
    },
    {
      title: useMessage('documents.title.6'),
      url: `${domain}${useMessage('documents.file.6')}`,
      content: useMessage('documents.content.6'),
    },
    {
      title: useMessage('documents.title.7'),
      url: useMessage('documents.file.7'),
      content: useMessage('documents.content.7'),
    },
    {
      title: useMessage('documents.title.8'),
      url: useMessage('documents.file.8'),
      content: useMessage('documents.content.8'),
    },
    {
      title: useMessage('documents.title.9'),
      url: useMessage('documents.file.9'),
      content: useMessage('documents.content.9'),
    },
    {
      title: useMessage('documents.title.10'),
      url: useMessage('documents.file.10'),
      content: useMessage('documents.content.10'),
    },
    {
      title: useMessage('documents.title.11'),
      url: useMessage('documents.file.11'),
      content: useMessage('documents.content.11'),
    },
    {
      title: useMessage('documents.title.12'),
      url: useMessage('documents.file.12'),
      content: '',
    },
    {
      title: useMessage('documents.title.13'),
      url: useMessage('documents.file.13'),
      content: '',
    },
    {
      title: useMessage('documents.title.14'),
      url: useMessage('documents.file.14'),
      content: '',
    },
    {
      title: useMessage('documents.title.15'),
      url: useMessage('documents.file.15'),
      content: '',
    },
  ]
  return (
    <Layout
      DocumentsPage
      bottomtitle={useMessage('landing.imprint')}
      toptitle={useMessage('landing.download.title')}
    >
      <Documents documentList={documentList} />
    </Layout>
  )
}

export default DocumentsPage
