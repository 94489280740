/* eslint-disable import/prefer-default-export */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadProposals } from 'redux/actions'

export default () => {
  const { proposals } = useSelector((state) => state)
  const { status } = useSelector((state) => state.appState)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!status) { // if page reloaded with type, the filter is not starting
      dispatch(loadProposals())
    }
  }, [status, dispatch])

  return proposals
}
