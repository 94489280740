import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    gridContainerPadding: {
      height: 'calc(100% - 65px)',
      padding: 10,
      overflowY: 'scroll',
    },
    gridItemPadding: {
      padding: 15,
    },
  })
  const classes = useStyles()
  return classes
}
