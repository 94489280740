import React from 'react'
import GraphContainer from 'components/Graph'
import SideNavigation from 'components/SideNavigation'
import NodeInfoView from 'components/NodeInfoView'
import GraphLegend from 'components/GraphLegend'
import {
  usePrint, Grid, PrintButton, PrintView,
} from 'lq-ui'
import PrintGraph from './GraphPrint'
import styles from './GraphConfigurationStyles'

const GraphConfiguration = () => {
  const [print, setPrint] = usePrint()
  const classes = styles()
  return (
    <Grid type="container" className={classes.container}>
      <Grid type="item" xs={7} sm={8} md={9} className={classes.graphContainer}>
        <div className={classes.legends}>
          <PrintButton handlePrint={setPrint} />
          <GraphLegend />
        </div>
        <GraphContainer className={classes.graph} />
      </Grid>
      <Grid type="item" xs={5} sm={4} md={3} className={classes.sideNavContainer}>
        <SideNavigation />
      </Grid>
      {print && <PrintGraph />}
      {print && (
        <PrintView>
          <NodeInfoView />
        </PrintView>
      )}
    </Grid>
  )
}

export default GraphConfiguration
