import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    paper: {
      margin: '.5rem',
      padding: '.5rem',
      height: '100%',
      width: '-webkit-fill-available',
    },
    button: {
      borderRadius: '4px',
      padding: '6px 8px',
      marginBottom: '.5rem',
      backgroundColor: '#760074',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#209BCD',
        color: '#fff',
      },
    },
    heading: {
      color: '#085986 !important',
      fontWeight: 'bold',
    },
    iktrue: {
      color: '#3d0886 !important',
      fontWeight: 'bold',
    },
  })

  return useStyles()
}
