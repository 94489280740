import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    textColor: {
      color: '#0f5886',
    },
  })

  const classes = useStyles()
  return classes
}
