import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    heading: {
      textAlign: 'center',
      display: 'block',
      maring: '100px',
    },
    impressumContent: {
      align: 'center',
      maxWidth: '100%',
      marginTop: '4rem',
      marginLeft: '1rem',
      whiteSpace: 'pre-line',
    },
  })
  const classes = useStyles()
  return classes
}
