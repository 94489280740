import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Heading } from 'lq-ui'

import './AddressPreview.scss'

const AddressPreview = ({ source, changed }) => (
  <div className={classnames('address-preview', changed && 'changed')}>
    {source ? (
      <>
        <img className="address-preview-map" src={source} alt="Kartenausschnitt Adresse" />
        {changed && (
        <div className="address-preview-changed">
          <Heading level="h6" color="#085986">Die Adresse hat sich geändert.</Heading>
          Schließen sie die Bearbeitung des Adressfeldes ab,
          um einen aktualisierten Kartenausschnitt zu sehen.
          (Feld verlassen oder [ENTER] betätigen.)
        </div>
        )}
      </>
    ) : (
      <div className="address-preview-empty">
        Füllen sie das Adressfeld, um den entsprechenden Kartenausschnitt zu sehen.
      </div>
    )}
  </div>
)

AddressPreview.defaultProps = {
  source: '',
  changed: false,
}

AddressPreview.propTypes = {
  source: PropTypes.string,
  changed: PropTypes.bool,
}

export default AddressPreview
