import React from 'react'
import { hooks } from 'lq-ui'
import PropTypes from 'prop-types'
import styles from './styles'
import ActionButton from './ActionButton'

const MeetingActionButtons = ({
  value, onChange, handleDelete, isProposal, user, handleAcceptProposal,
  handleRejectProposal, type, addNew,
}) => {
  const classes = styles()
  const handleSave = () => {
    onChange(!value)
    // handleUpdate()
  }
  const deleteItem = () => {
    handleDelete()
  }
  const handleClick = () => {
    onChange(!value)
  }
  // const saveLabel = hooks.i18n.useMessage('editor.addNewNode.save.button')
  const editLabel = hooks.i18n.useMessage('general.edit')
  const deleteLabel = hooks.i18n.useMessage('general.delete')
  const addNewLabel = hooks.i18n.useMessage(!user ? 'proposeNewMeeting.button' : 'addNewMeeting.button')

  const RenderEditing = () => {
    if (isProposal && user) {
      return (
        <div className={classes.buttonGroup}>
          <ActionButton
            action="Check"
            className={classes.buttonWidth}
            // label="Aprrove Proposal"
            onClick={handleAcceptProposal}
            backgroundColor="#4caf50"
            type={type}
          />
          <ActionButton
            action="Clear"
            type={type}
            className={classes.buttonWidth}
            // label="Reject Proposal"
            onClick={handleRejectProposal}
          />
          <ActionButton
            action="Delete"
            type={type}
            className={classes.buttonWidth}
            // label="Remove Proposal"
            onClick={deleteItem}
            backgroundColor="#B00020"
          />
        </div>
      )
    }
    if (!isProposal && user) {
      return (
        <div className={classes.buttonGroup}>
          <ActionButton
            action="Edit"
            label={editLabel}
            onClick={handleClick}
            type={type}
          />
          <ActionButton
            action="Delete"
            label={deleteLabel}
            onClick={deleteItem}
            backgroundColor="#B00020"
            type={type}
          />
        </div>
      )
    }
    return (
      <ActionButton
        action="Edit"
        className={classes.buttonWidth}
        label={hooks.i18n.useMessage('meeting.propose.edit')}
        onClick={handleClick}
        type={type}
      />
    )
  }

  const RenderAdding = () => (
    <ActionButton
      action="Add"
      className={addNew ? classes.addNewButton : classes.buttonWidth}
      label={addNewLabel}
      onClick={handleSave}
      type={type}
      newMeeting
    />
  )

  return <>{value ? RenderEditing() : RenderAdding()}</>
}

MeetingActionButtons.defaultProps = {
  handleDelete: () => null,
  handleAcceptProposal: () => null,
  handleRejectProposal: () => null,
  type: '',
  addNew: false,
}

MeetingActionButtons.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  // handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  isProposal: PropTypes.bool.isRequired,
  user: PropTypes.bool.isRequired,
  handleAcceptProposal: PropTypes.func,
  handleRejectProposal: PropTypes.func,
  type: PropTypes.string,
  addNew: PropTypes.bool,

}

export default MeetingActionButtons
