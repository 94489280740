/* eslint-disable react/prop-types */
import React from 'react'
import { hooks, TextField } from 'lq-ui'
import PropTypes from 'prop-types'

const ProviderContactDetail = ({
  editing, type, isLink, content,
}) => {
  const label = hooks.i18n.useMessage(`providers.card.${type}`)
  return editing ? (
    <TextField
      value={content.value}
      label={label}
      rows="2"
      onChange={content.onChange}
    />
  ) : (
    content.value
      ? (
        <div
          style={{ wordBreak: 'break-word', cursor: 'pointer', color: '#0f5886' }}
          role="button"
          id="providerCardContent"
          tabIndex={0}
          onClick={() => isLink && window.open(!content.value.startsWith('http')
            ? `http://${content.value}`
            : content.value, '_blank')}
          onKeyDown={null}
        >
          <b>{label}</b>
          <span>
            {content.value}
          </span>
        </div>
      )
      : null
  )
}

ProviderContactDetail.defaultProps = {
  provider: {},
  type: '',
  isLink: false,
}

ProviderContactDetail.propTypes = {
  provider: PropTypes.shape({
    id: PropTypes.number,
  }),
  type: PropTypes.string,
  isLink: PropTypes.bool,
}

export default ProviderContactDetail
