import { useJss } from 'lq-ui'

export default (labelColor, backgroundColor) => {
  const useStyles = useJss({
    label: {
      marginLeft: '5px',
      color: labelColor,
    },
    button: {
      marginTop: 10,
      marginBottom: 10,
      width: '100%',
      backgroundColor,
      '&:hover': {
        backgroundColor: '#760074',
      },
    },
  })
  const classes = useStyles()
  return classes
}
