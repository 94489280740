import React from 'react'
import PropTypes from 'prop-types'
import TopNavigation from 'components/TopNavigation'
import BottomNavigation from 'components/BottomNavigation'
import styles from './LayoutStyles'

const Layout = ({
  bottomtitle, children, landingPage, toptitle, showSkyline,
}) => {
  const classes = styles()
  return (
    <div className={classes.layout}>
      {!landingPage && <TopNavigation title={toptitle} />}
      {children}
      <BottomNavigation title={bottomtitle} showSkyline={showSkyline} />
    </div>
  )
}

Layout.defaultProps = {
  bottomtitle: '',
  // noBottom: false,
  landingPage: false,
  toptitle: '',
  showSkyline: false,
}

Layout.propTypes = {
  bottomtitle: PropTypes.string,
  // noBottom: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  landingPage: PropTypes.bool,
  toptitle: PropTypes.string,
  showSkyline: PropTypes.bool,
}

export default Layout
