import React from 'react'
import PropTypes from 'prop-types'
import { PrintButton } from 'lq-ui'
import styles from './ProvidersTopStyles'

const ProvidersTop = ({ handlePrint }) => {
  const classes = styles()
  return (<div className={classes.printButton}><PrintButton handlePrint={handlePrint} /></div>)
}

ProvidersTop.propTypes = {
  handlePrint: PropTypes.func.isRequired,
}

export default ProvidersTop
