/* eslint-disable no-param-reassign */
import React, { useRef, memo } from 'react'
import PropTypes from 'prop-types'
import * as vis from 'vis-network'
import { useDispatch, useSelector } from 'react-redux'
import { selectNode } from 'redux/actions'
import { getPersona } from 'redux/selectors'
import options from './GraphOptions'

const Graph = ({
  nodesData, edgesData, className, id, isEditor,
}) => {
  const refNetworkContainer = useRef()
  const dispatch = useDispatch()
  const persona = useSelector(getPersona)

  React.useEffect(() => {
    const container = refNetworkContainer.current
    // const highlightSelectedNode = (values) => {
    //   values.shadow = true
    //   values.shadowSize = 17
    //   values.shadowColor = 'rgba(118, 0, 116,0.9)'
    //   values.shadowX = 0
    //   values.shadowY = 0
    //   values.size = 90
    // }
    const nodes = nodesData
    const edges = edgesData.map((e) => ({
      ...e,
      arrows: {
        to: {
          enabled: false,
          scaleFactor: 0,
          type: 'arrow',
        },
      },
    }))
    const network = new vis.Network(container, { edges, nodes }, options)

    network.on('selectNode', (params) => {
      const node = params.nodes[0]
      network.setSelection({
        nodes: [],
        edges: [],
      })
      dispatch(selectNode(node))
    })
  }, [dispatch, edgesData, isEditor, nodesData, persona])
  return <div id={id} ref={refNetworkContainer} className={className} />
}

Graph.defaultProps = {
  className: '',
  highlightedGraph: {
    nodes: [],
    edges: [],
  },
}

Graph.propTypes = {
  id: PropTypes.string.isRequired,
  nodesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string,
    }),
  ).isRequired,
  edgesData: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.number.isRequired,
      to: PropTypes.number.isRequired,
    }),
  ).isRequired,
  highlightedGraph: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.number),
    edges: PropTypes.arrayOf(PropTypes.string),
  }),
  className: PropTypes.string,
  isEditor: PropTypes.bool.isRequired,
}

export default memo(Graph)
