import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    card: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    adjust: {
      alignSelf: 'center',
      justifyContent: 'space-between',
      flexDirection: 'column',
      height: '100%',
      display: 'flex',
      width: '100%',
      color: '#085986',
    },
  })
  const classes = useStyles()
  return classes
}
