import api from 'api'

export const loadGraph = () => async (dispatch) => {
  const graph = await api.graph.get()
  return dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
}

export const selectNode = (node) => ({
  type: 'GRAPH_SELECT',
  payload: node,
})

export const updateCriteria = (criteria) => ({
  type: 'UPDATE_CRITERIA',
  payload: criteria,
})

export const updateConditions = (condition) => ({
  type: 'UPDATE_CONDITIONS',
  payload: condition,
})

export const updateCriteriaValues = (criteriaValues) => ({
  type: 'UPDATE_CRITERIA_VALUES',
  payload: criteriaValues,
})

export const resetCriteriaValues = () => ({
  type: 'RESET_CRITERIA_VALUES',
})

export const updateNodeInformation = (node, nodeEdges) => async (dispatch, getState) => {
  await dispatch({ type: 'UPDATE_NODE_INFORMATION', payload: { node, nodeEdges } })
  const {
    graph: {
      nodes, edges, providers, meetings,
    },
  } = getState()
  const updatedNodes = nodes.map((n) => n.id).includes(node.id)
    ? nodes.map((n) => (n.id === node.id ? node : n))
    : [...nodes, node]

  const graph = await api.graph.set({
    nodes: updatedNodes,
    edges: nodeEdges ? [...edges, ...nodeEdges] : edges,
    providers,
    meetings,
  })
  return dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
}

export const updateEdgeConditions = (edge, newConditionLabel) => async (dispatch, getState) => {
  await dispatch({ type: 'UPDATE_EDGE_CONDITIONS', payload: { edge, newConditionLabel } })
  const {
    graph: {
      nodes, edges, providers, meetings,
    },
  } = getState()

  const updateEdge = (e) => (newConditionLabel ? ({ ...e, [newConditionLabel]: '' }) : e)
  const udpdatedEdges = edges.map((e) => e.id).includes(edge.id)
    ? edges.map((e) => (e.id === edge.id ? edge : updateEdge(e)))
    : [...edges.map((e) => updateEdge(e)), edge]
  const graph = await api.graph.set({
    nodes,
    edges: udpdatedEdges,
    providers,
    meetings,
  })
  return dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
}

export const deleteEdge = (edge) => async (dispatch, getState) => {
  await dispatch({ type: 'DELETE_EDGE', payload: edge })
  const {
    graph: {
      nodes, edges, providers, meetings,
    },
  } = getState()
  const graph = await api.graph.set({
    nodes,
    edges: edges.filter((e) => (e.id !== edge.id)),
    providers,
    meetings,
  })
  return dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
}

export const deleteNode = (node, nondeEdges) => async (dispatch, getState) => {
  await dispatch({ type: 'DELETE_NODE', payload: node })
  const {
    graph: {
      nodes, edges, providers, meetings,
    },
  } = getState()

  const graph = await api.graph.set({
    nodes: nodes.filter((n) => (n.id !== node.id)),
    edges: edges.filter((e) => !nondeEdges.map((ne) => ne.id).includes(e.id)),
    providers,
    meetings,
  })
  return dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
}

// Providers
export const selectProvider = (provider) => ({
  type: 'SELECT_PROVIDER',
  payload: provider,
})

export const updateProviderInformation = (provider, node) => async (dispatch, getState) => {
  await dispatch({ type: 'UPDATE_PROVIDER_INFORMATION', payload: provider })
  const {
    graph: {
      nodes, edges, providers, meetings,
    },
  } = getState()
  const providerId = Math.max(...providers.map((p) => p.id)) + 1

  const newProviders = provider.id
    ? providers.map((p) => (p.id === provider.id ? provider : p))
    : [...providers, { ...provider, id: providerId }]
  const graph = await api.graph.set({
    nodes: node ? nodes.map((n) => (n.id === node ? { ...n, providers: [...n.providers, providerId] } : n)) : nodes,
    edges,
    meetings,
    providers: newProviders,
  })
  return dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
}


// Meetings
export const updateMeetingInformation = (meeting) => async (dispatch, getState) => {
  await dispatch({ type: 'UPDATE_MEETING_INFORMATION', payload: meeting })
  const {
    graph: {
      nodes, edges, providers, meetings,
    },
  } = getState()

  const updatedMeetings = meetings.map((m) => m.id).includes(meeting.id)
    ? [...meetings.filter((m) => m.id !== meeting.id), meeting]
    : [...meetings, meeting]

  const graph = await api.graph.set({
    nodes,
    edges,
    providers,
    meetings: updatedMeetings,
  })
  return dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
}

export const loadProposals = () => async (dispatch) => {
  const proposals = await api.proposals.get()
  return dispatch({ type: 'PROPOSALS_LOAD_SUCCESS', payload: proposals })
}


export const proposeMeeting = (meeting) => async (dispatch) => {
  await dispatch({ type: 'PROPOSE_MEETING', payload: meeting })
  const graph = await api.proposals.set(meeting)
  return dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
}

export const acceptProposal = (proposalId) => async (dispatch, getState) => {
  const { proposals } = getState()
  await api.proposals.accept(proposalId).then(
    dispatch({ type: 'ACCEPT_PROPOSAL', payload: proposals.filter((p) => p.id !== proposalId) }),
  )
}

export const rejectProposal = (proposalId) => async (dispatch, getState) => {
  const { proposals } = getState()
  await api.proposals.reject(proposalId).then(
    dispatch({ type: 'REJECT_PROPOSAL', payload: proposals.filter((p) => p.id !== proposalId) }),
  )
}

export const removeProposal = (proposalId) => async (dispatch, getState) => {
  const { proposals } = getState()
  await api.proposals.remove(proposalId).then(
    dispatch({ type: 'REMOVE_PROPOSAL', payload: proposals.filter((p) => p.id !== proposalId) }),
  )
}

export const deleteMeeting = (meetingId) => async (dispatch, getState) => {
  await dispatch({ type: 'DELETE_MEETING', payload: meetingId })
  const {
    graph: {
      nodes, edges, providers, meetings,
    },
  } = getState()

  const graph = await api.graph.set({
    nodes,
    edges,
    providers,
    meetings: meetings.filter((m) => m.id !== meetingId),
  })
  return dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
}


export const deleteProvider = (providerId) => async (dispatch, getState) => {
  await dispatch({ type: 'DELETE_PROVIDER', payload: providerId })
  const {
    graph: {
      nodes, edges, providers, meetings,
    },
  } = getState()

  const graph = await api.graph.set({
    nodes,
    edges,
    providers: providers.filter((p) => p.id !== providerId),
    meetings,
  })

  return dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
}
