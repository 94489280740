import React from 'react'
import PropTypes from 'prop-types'
import styles from './ProviderContentHeaderStyles'

const ProviderContentHeader = ({ showDescription, shortDescription, description }) => {
  const classes = styles()
  return !!showDescription && (
    <div className={classes.textColor}>
      <span>{shortDescription}</span>
      <p>{description}</p>
    </div>
  )
}

ProviderContentHeader.defaultProps = {
  description: '',
}

ProviderContentHeader.propTypes = {
  description: PropTypes.string,
  showDescription: PropTypes.bool.isRequired,
  shortDescription: PropTypes.string.isRequired,
}

export default ProviderContentHeader
