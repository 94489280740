import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    heading: {
      whiteSpace: 'normal !important',
      padding: '20px !important',
      margin: '20px',
    },
    landingHeader: {
      textAlign: 'center !important',
      display: 'block !important',
      margin: '20px !important',
      whiteSpace: 'initial !important',
    },
    mainLabel: {
      whiteSpace: 'normal !important',
      padding: '5px !important',
    },
  })
  return useStyles()
}
