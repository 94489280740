import React from 'react'
import GraphContainer from 'components/Graph'
import { Grid } from 'lq-ui'
import GraphLegend from 'components/GraphLegend'
import SideNavigation from 'components/SideNavigation'
import styles from './EditorStyles'

const Editor = () => {
  const classes = styles()
  return (
    <Grid type="container" className={classes.container}>
      <Grid type="item" xs={9} className={classes.graphContainer}>
        <div className={classes.legends}><GraphLegend /></div>
        <GraphContainer className={classes.graph} isEditor />
      </Grid>
      <Grid type="item" xs={3} className={classes.sideNavContainer}>
        <SideNavigation isEditor />
      </Grid>
    </Grid>
  )
}
export default Editor
