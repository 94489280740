import React from 'react'
import useMeetings from 'services/useMeetings'
import MeetingsList from 'components/Meetings/MeetingsList'
import Menu from 'components/Menu'
import { Grid, hooks } from 'lq-ui'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import useProposals from 'services/useProposals'
import MeetingsInfo from 'components/Meetings/MeetingsInfo'
import { useAuth } from 'lq-ui/lib/auth/use-auth'
import styles from './MeetingsStyles'
import { loadProposals } from 'redux/actions'
import { useDispatch } from 'react-redux'

const sortMeetingsMenuItem = (items) => items.sort((a, b) => {
  if (a.label < b.label) { return -1 }
  if (a.label > b.label) { return 1 }
  return 0
})

const Meetings = ({ proposalsRoute }) => {
  const { user } = useAuth()
  const classes = styles()
  const meetings = useMeetings()
  const proposals = useProposals()
  const { match: { path } } = hooks.router.useRouter()
  const dispatch = useDispatch()

  const main = user
    ? [proposalsRoute
      ? { label: 'Sprachgelegenheiten', route: 'meetings' } : { label: 'Vorschläge', route: 'proposals' }]
    : []

  const meetingsMenuItems = meetings.reduce((a, m) => (a.includes(m.district.toLowerCase())
    ? a : [...a, m.district.toLowerCase()]),
  []).map((i) => ({ label: i, route: i }))
  const proposalsMenuItems = proposals.reduce((a, m) => (a.includes(m.district.toLowerCase())
    ? a : [...a, m.district.toLowerCase()]),
  []).map((i) => ({ label: i, route: i }))

  React.useEffect(() => {
    if(proposalsRoute) {
          dispatch(loadProposals())
    }
  }, [])




  return (
    <>
      <Grid
        type="container"
        flexDirection="row"
        justifyContent="center"
        alignItems="flex-start"
        className={classes.gridContainerPadding}
      >
        <Grid type="item" xs={12} lg={2} className={classes.gridItemPadding}>
          <Menu
            menuItems={[...sortMeetingsMenuItem(proposalsRoute ? proposalsMenuItems : meetingsMenuItems), ...main]}
          />
        </Grid>
        <Grid type="item" xs={12} lg={10} className={classes.gridItemPadding}>
          <Grid
            type="container"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Switch>
              <Route exact path={path}>
                <MeetingsInfo proposalsRoute={proposalsRoute} proposals={proposals.length} />
              </Route>
              <Route path={`${path}/:district`}>
                <MeetingsList
                  meetings={proposalsRoute ? proposals : meetings}
                  isProposal={proposalsRoute}
                />
              </Route>
            </Switch>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
Meetings.defaultProps = {
  proposalsRoute: false,
}
Meetings.propTypes = {
  proposalsRoute: PropTypes.bool,
}

export default Meetings
