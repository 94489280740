import { createSelector } from 'reselect'
import { computeGraph, computeGraphByConditions } from './graph'

export const conditionsSelector = createSelector(
  (state) => state.graph.edges,
  (edges) => {
    const values = edges
      .reduce((o, e) => Object.entries(e)
        .reduce((oo, [k, v]) => (v && !['id', 'from', 'to', 'arrows'].includes(k) ? {
          ...oo,
          [k]: [...new Set([...(oo[k] || []), v])],
        } : oo), o), {})
    return Object.entries(values)
      .reduce((a, [k, v]) => ({
        ...a,
        [k]: v.includes(true) || v.includes(false)
          ? { name: k, type: 'bool', items: ['Ja', 'Nein'] }
          : { name: k, type: 'select', items: v },
      }), {})
  },
)

export const computeNextCondition = createSelector(
  computeGraph,
  (state) => state.appState.persona,
  (graph, persona) => {
    const starting = graph.edges.filter((e) => !e.from)
    const rates = starting
      .filter((edge) => Object.entries(edge).every(([k, v]) => !v || !persona[k] || persona[k] === v))
      .reduce((o, e) => Object.entries(e)
        .filter(([k]) => !['id', 'from', 'to', ...Object.keys(persona)].includes(k))
        .reduce((oo, [k, v]) => ({
          ...oo,
          [k]: { ...(oo[k] || {}), [(v || '*')]: ((oo[k] || {})[v || '*'] || 0) + 1 },
        }), o), {})
    const sorted = Object.entries(rates)
      .filter(([, v]) => {
        const values = Object.keys(v)
        return values.length > 1 || !values.includes('*')
      })
      .sort(([, va], [, vb]) => (va['*'] === vb['*'] ? 0 : va['*'] < vb['*'] ? -1 : +1))
    return !sorted.length || !sorted[0][1]['*'] ? null : sorted[0][0]
  },
)

export const computeCriteriaValues = createSelector(
  computeNextCondition,
  conditionsSelector,
  (nextCondition, conditionsValues) => ({ [nextCondition]: conditionsValues[nextCondition] }),
)

export const getConditions = createSelector(
  (state) => state.appState.selectedNode,
  computeGraphByConditions,
  computeGraph,
  conditionsSelector,
  // (state) => state.appState.persona,
  (node, displayedgraph, graph, allConditions) => {
    const outboundEdges = node !== undefined
      ? graph.edges.filter((e) => e.from === node)
      : []
    const inboundEdges = node !== undefined
      ? displayedgraph.edges.filter((e) => e.to === node)
      : []
    const computeConditions = (nodeEdges) => nodeEdges
      .map((e) => Object.entries(e)
        .reduce((acc, [key, value]) => (
          Object.keys(allConditions).includes(key)
        && (value !== '')
            ? [...acc, key]
            : acc), [])).flat()
      .reduce((acc, i) => (!acc.includes(i) ? [...acc, i] : acc), [])
    return ({
      outbound: computeConditions(outboundEdges),
      inbound: computeConditions(inboundEdges),
    })
  },
)

export const getAllConditions = createSelector(
  computeGraph,
  computeGraphByConditions,
  conditionsSelector,
  (graph, displayedGraph, allConditions) => {
    const allEdges = graph.edges.filter((e) => displayedGraph.nodes.map((n) => n.id).includes(e.from))
    const computeConditions = (edges) => edges
      .filter((e) => e.from)
      .map((e) => Object.entries(e)
        .reduce((acc, [key, value]) => (
          Object.keys(allConditions).includes(key)
        && (value !== '')
            ? [...acc, key]
            : acc), [])).flat()
      .reduce((acc, i) => (!acc.includes(i) ? [...acc, i] : acc), [])

    return computeConditions(allEdges)
  },
)

export const getConditionValues = (condition) => createSelector(
  conditionsSelector,
  (conditionsValues) => conditionsValues[condition],
)

export const getPersona = createSelector(
  (state) => state.appState.persona,
  (persona) => persona,
)
