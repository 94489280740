import React from 'react'
import PropTypes from 'prop-types'
import EditorPanel from 'components/GraphEditor/EditorPanel'
import NodeInfoConfigurator from 'components/NodeInfoConfigurator'

const SideBar = ({ isEditor, selectedProvider }) => (
  <>
    {isEditor && <EditorPanel /> }
    {!isEditor && !selectedProvider && <NodeInfoConfigurator />}
  </>
)

SideBar.defaultProps = {
  selectedProvider: undefined,
  isEditor: undefined,
}

SideBar.propTypes = {
  selectedProvider: PropTypes.objectOf(PropTypes.any),
  isEditor: PropTypes.bool,
}

export default SideBar
