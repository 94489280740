import React from 'react'
import PropTypes from 'prop-types'
import MeetingCard from 'components/Meetings/MeetingCard'
import sortProviders from 'services/sortProviders'

const MeetingsGrid = ({
  items, showDescription, isEditor, fixedWidth, isProposal, isPrinting,
}) => {
  const sortedProviders = isEditor ? items : sortProviders(items)
  return (
    <>
      {sortedProviders.map((item) => (
        <MeetingCard
          key={item.id}
          meeting={item}
          isEditor={isEditor}
          showDescription={showDescription}
          print={isPrinting}
          fixedWidth={fixedWidth}
          isProposal={isProposal}
        />
      ))}
    </>
  )
}

MeetingsGrid.defaultProps = {
  isPrinting: false,
  showDescription: false,
  isEditor: false,
  fixedWidth: false,
  isProposal: false,
}

MeetingsGrid.propTypes = {
  isPrinting: PropTypes.bool,
  isEditor: PropTypes.bool,
  showDescription: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  isProposal: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    map: PropTypes.string,
  })).isRequired,
}

export default MeetingsGrid
