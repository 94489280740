import React from 'react'
import { useSelector } from 'react-redux'
import ConditionsGroup from 'components/ConditionsGroup'
import {
  getConditions, getAllConditions, computeNextCondition, nodeSelector,
} from 'redux/selectors'


const NodeConditions = () => {
  const nodeConditions = useSelector(getConditions)
  const { persona } = useSelector((state) => state.appState)
  const selectedNode = useSelector(nodeSelector)
  const allConditions = useSelector(getAllConditions)
  const nextCondition = useSelector(computeNextCondition)

  const personaCombinedConditions = [...Object.keys(persona), nextCondition, ...allConditions]
    .reduce((a, c) => (a.includes(c) ? a : [...a, c]), [])

  return (
    <>
      { !selectedNode
        ? <ConditionsGroup conditionsLabels={personaCombinedConditions} title="Bedingungen" />
        : (
          <div>
            <ConditionsGroup conditionsLabels={nodeConditions.inbound} title="Eingangs Bedingungen" />
            <ConditionsGroup conditionsLabels={nodeConditions.outbound} title="Ausgangs Bedingungen" />
          </div>
        )}
    </>
  )
}

export default NodeConditions
