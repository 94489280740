import React, { lazy, Suspense } from 'react'
import {
  Icon, Tabs, Heading, Paper,
} from 'lq-ui'
import useNodeInfo from 'services/useNodeInfo'
import { useSelector } from 'react-redux'
import LoadingSpinnerView from 'components/LoadingSpinnerComponent/LoadingSpinnerView'
import NodeSelect from 'components/GraphEditor/NodeSelect'
import DeleteNode from 'components/GraphEditor/DeleteNode'
import styles from './EditorPanelStyles'

const EditorPanel = () => {
  const [value, setValue] = React.useState(0)
  const handleChange = (_, newValue) => setValue(newValue)
  const [node, , nodeProviders] = useNodeInfo()
  const { graph: { providers } } = useSelector((state) => state)
  const NodeInformation = lazy(() => import('components/GraphEditor/NodeInformation'))
  const NodeConditionsEditor = lazy(() => import('components/GraphEditor/NodeConditionsEditor'))
  const NodeProviders = lazy(() => import('components/GraphEditor/NodeProviders'))
  const classes = styles()
  return (
    <div>
      <NodeSelect />
      { node
      && (
      <Paper className={classes.header}>
        <Heading level="h5" color="#085986" className={classes.heading}>
          {node && node.name}
        </Heading>
        <DeleteNode />
      </Paper>
      )}
      <Tabs
        activeKey={value}
        onSelect={handleChange}
        tabButtons={[
          { label: 'Voraussetzungen', icon: <Icon color={value === 0 ? '#085986' : '#757575'} iconName="Tune" /> },
          { label: 'Informationen', icon: <Icon color={value === 1 ? '#085986' : '#757575'} iconName="Description" /> },
          { label: 'Anbieter', icon: <Icon color={value === 2 ? '#085986' : '#757575'} iconName="Apartment" /> },
        ]}
        header={(
          <Heading level="h5" color="#085986" className={classes.header}>
            {node && node.name}
          </Heading>
        )}
      >
        <Tabs.Content value={value} index={0}>
          <Suspense fallback={<LoadingSpinnerView />}>
            <NodeConditionsEditor node={node} />
          </Suspense>
        </Tabs.Content>
        <Tabs.Content value={value} index={1}>
          <Suspense fallback={<LoadingSpinnerView />}>
            <NodeInformation node={node} />
          </Suspense>
        </Tabs.Content>
        <Tabs.Content value={value} index={2}>
          <Suspense fallback={<LoadingSpinnerView />}>
            <NodeProviders
              node={node}
              nodeProviders={nodeProviders}
              allProviders={providers.filter((p) => p.type === '')}
            />
          </Suspense>
        </Tabs.Content>
      </Tabs>
    </div>
  )
}

export default EditorPanel
