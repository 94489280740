import React from 'react'
import SignIn from 'components/SignIn'
import { hooks } from 'lq-ui'
import Layout from 'components/Layout'

export default () => (
  <Layout
    ProviderPage
    bottomtitle={hooks.i18n.useMessage('landing.imprint')}
    toptitle={hooks.i18n.useMessage('providers.toptitle')}
  >
    <SignIn />
  </Layout>
)
