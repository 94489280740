import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    info: {
      width: '100%',
      padding: '0 .5rem',
      maxWidth: '100rem',
      textAlign: 'center',
      overflowX: 'hidden',
    },
    infoMessage: {
      color: '#085986',
      borderRadius: '3px',
      boxShadow: '-1px 2px 6px 1px #000',
      background: 'transparent',
      padding: '.5rem',
      lineHeight: '1.6',
      fontWeight: 'bold',
      whiteSpace: 'pre-wrap',
    },
    image: {
      maxWidth: '100%',
      width: '100%',
    },
    mobile: {
      textAlign: 'center',
    },
    '@media (min-width: 812px)': {
      infoMessage: {
        padding: '2rem',
      },
      info: {
        padding: '0 5rem',
      },
    },
  })
  const classes = useStyles()
  return classes
}
