import React from 'react'
import { hooks, useRequireAuth } from 'lq-ui'
import Layout from 'components/Layout'
import Meetings from 'components/Meetings'
import LoadingSpinnerView from 'components/LoadingSpinnerComponent/LoadingSpinnerView'

const Proposals = () => {
  const auth = useRequireAuth()
  if (!auth.user) {
    return <LoadingSpinnerView />
  }

  return (
    <Layout
      MeetingsPage
      bottomtitle={hooks.i18n.useMessage('landing.imprint')}
      toptitle={hooks.i18n.useMessage('meetings.toptitle')}
    >
      <Meetings proposalsRoute />
    </Layout>
  )
}

export default Proposals
