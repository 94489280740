import React from 'react'
import PropTypes from 'prop-types'
import { Avatar } from 'lq-ui'
import styles from './BubbleContentStyles'

const BubbleContent = ({
  icon, label, size, color, badge,
}) => {
  const classes = styles(size, color, badge && badge.color)
  return (
    <Avatar className={classes.bubble}>
      <div className={classes.bubbleContent}>
        <i className={`fas fa-${icon} ${classes.bubbleIcon}`} />
        <div className={classes.bubbleLabel}><b>{label}</b></div>
      </div>
    </Avatar>
  )
}

BubbleContent.defaultProps = {
  badge: null,
}

BubbleContent.propTypes = {
  badge: PropTypes.shape({
    color: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
}

export default BubbleContent
