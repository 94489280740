import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    container: {
      backgroundColor: '#085986',
    },
    grow: {
      flexGrow: 1,
    },
    button: {
      color: '#fff !important',
    },
    preWrap: {
      whiteSpace: 'pre-wrap',
    },
    homeLogo: {
      width: '50px',
      paddingBottom: '5px',
    },
    logoutLabel: {
      fontSize: 'large',
      padding: '5px',
      textTransform: 'uppercase',
    },
    logoutIcon: {
      fontSize: '1.5rem',
    },
  })
  const classes = useStyles()
  return classes
}
