import React from 'react'
import SignInForm from 'components/SignInForm'
import {
  Container,
  Heading,
  hooks,
} from 'lq-ui'
import styles from './SignInStyles'

const SignIn = () => {
  const classes = styles()
  return (
    <Container component="div" maxWidth="xs" className={classes.container}>
      <div className={classes.paper}>
        <i className={`fas fa-sign-in-alt ${classes.signInIcon}`} />
        <Heading level="h5" color="#085986">
          {hooks.i18n.useMessage('signin.label')}
        </Heading>
        <SignInForm
          emailLabel={hooks.i18n.useMessage('signin.label')}
          passwordLabel={hooks.i18n.useMessage('signin.password')}
          signInLabel={hooks.i18n.useMessage('signin.label')}
        />
      </div>
    </Container>
  )
}

export default SignIn
