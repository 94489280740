import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'lq-ui'
import styles from './MeetingViewStyles'
import RenderMeetingViewElement from './RenderMeetingViewElement'


const MeetingView = ({ meeting, isProposal }) => {
  const classes = styles()
  const meetingContent = [
    'email', 'phone', 'serviceHours', 'contactName',
    'address', 'shortDescription', 'provider', 'district',
  ]
  return (
    <Card className={classes.card}>
      <div className={classes.meetingName}>
        {meeting.name}
      </div>
      <Card.Content>
        {(isProposal ? [...meetingContent, 'proposedBy'] : meetingContent)
          .map((e) => <RenderMeetingViewElement label={e} meeting={meeting} key={e} />)}
        {/* <div style={{ wordBreak: 'break-word', color: '#0f5886' }}>
          <b>{hooks.i18n.useMessage('card.serviceHours')}</b>
          <span>
            {meeting.serviceHours}
          </span>
        </div> */}
      </Card.Content>

    </Card>
  )
}

MeetingView.defaultProps = {
  isProposal: false,
}

MeetingView.propTypes = {
  meeting: PropTypes.objectOf(PropTypes.string).isRequired,
  isProposal: PropTypes.bool,
}

export default MeetingView
