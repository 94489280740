import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { createTheme, Root } from 'lq-ui'
import GraphPage from 'pages/GraphPage'
import LandingPage from 'pages/LandingPage'
import ImpressumPage from 'pages/ImpressumPage'
import ProviderPage from 'pages/ProviderPage'
import MeetingsPage from 'pages/MeetingsPage'
import SignInPage from 'pages/SignInPage'
import EditorPage from 'pages/EditorPage'
import DocumentsPage from 'pages/DocumentsPage'
import ProposalsPage from 'pages/Proposals'
import pages from 'pages/routes.json'
import messages from '../messages/de.json'

const theme = createTheme({
  overrides: {
    MuiDrawer: {
      paper: {
        width: '30%',
        zIndex: 0,
      },
    },
    MuiContainer: {
      root: {
        marginLeft: 0,
      },
    },
    MuiSelect: {
      selectMenu: {
        whiteSpace: 'inherit',
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '10px 14px',
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 11.5px) scale(1)',
      },
    },
  },
  palette: {
    primary: { 500: '#085986' },
    secondary: { A400: '#fff' },
  },
  typography: {
    h3: {
      fontFamily: [
        '"Helvetica Neue"',
        'IBM Plex Sans',
        'Roboto',
        'Arial',
        'sans-serif',
      ].join(','),
    },
    h5: {
      fontFamily: [
        '"Helvetica Neue"',
        'IBM Plex Sans',
        'Roboto',
        'Arial',
        'sans-serif',
      ].join(','),
    },
    h6: {
      fontFamily: [
        '"Helvetica Neue"',
        'IBM Plex Sans',
        'Roboto',
        'Arial',
        'sans-serif',
      ].join(','),
    },
  },
})

const App = () => {
  document.body.style.backgroundColor = '#fff'
  const routes = [
    { path: pages.providers.root, component: ProviderPage, exact: true },
    { path: pages.meetings, component: MeetingsPage, exact: false },
    { path: pages.root, component: LandingPage, exact: true },
    { path: pages.graph, component: GraphPage, exact: true },
    { path: pages.documents, component: DocumentsPage, exact: true },
    { path: pages.signin, component: SignInPage, exact: true },
    { path: pages.impressum, component: ImpressumPage, exact: true },
    { path: pages.editor, component: EditorPage, exact: true },
    { path: pages.proposals, component: ProposalsPage, exact: false },
  ]
  return (
    <Root theme={theme} auth="backend" message={messages}>
      <Router>
        <Switch>
          { routes.map(({ path, component, exact }) => (
            <Route
              exact={exact}
              path={path}
              component={component}
              key={path}
            />
          ))}
        </Switch>
      </Router>
    </Root>
  )
}

export default App
