import React from 'react'
import PropTypes from 'prop-types'
import { usePrint, Grid } from 'lq-ui'
import ProviderForm from 'components/ProviderForm'
import ProviderCardPrint from './ProviderCardPrint'

const ProviderCard = ({
  print, provider, showDescription, isEditor, isSideBar,
}) => {
  const [isPrintCard, setPrintCard] = usePrint()
  return (
    <Grid type="item" xs={12} sm={isSideBar ? 12 : 6} md={isSideBar ? 12 : 4} lg={isSideBar ? 12 : 3}>
      <ProviderForm
        isEditor={isEditor}
        print={print}
        provider={provider}
        setPrint={setPrintCard}
        showDescription={showDescription}
        noButton
      />
      {isPrintCard && (
      <ProviderCardPrint
        print={isPrintCard}
        provider={provider}
        showDescription={showDescription}
      />
      )}
    </Grid>
  )
}

ProviderCard.defaultProps = {
  isSideBar: false,
  print: false,
  isEditor: false,
}

ProviderCard.propTypes = {
  isEditor: PropTypes.bool,
  isSideBar: PropTypes.bool,
  print: PropTypes.bool,
  provider: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    map: PropTypes.string,
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  showDescription: PropTypes.bool.isRequired,
}

export default ProviderCard
