import React from 'react'
import PropType from 'prop-types'
import {
  Card, Grid, Heading, Icon,
} from 'lq-ui'
import ProviderCard from 'components/ProviderCard'
import styles from './styles'

const NodeInfoExtended = ({ nodeDescription, nodeProviders, noButton }) => {
  const classes = styles()
  return (
    <Card.Content>
      <span className={classes.span}>{nodeDescription}</span>
      <div className={classes.headerContainer}>
        <Heading level="h5" color="#085986" className={classes.headerText}>Anbieter</Heading>
        <Icon iconName="Apartment" color="#085986" />
      </div>
      <Grid
        type="container"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {nodeProviders
          .map((provider) => (
            <ProviderCard
              provider={provider}
              key={provider.id}
              noButton={!!noButton}
              showDescription
              isSideBar
            />
          ))}
      </Grid>
    </Card.Content>
  )
}
NodeInfoExtended.defaultProps = {
  nodeDescription: '',
  noButton: false,
}

NodeInfoExtended.propTypes = {
  noButton: PropType.bool,
  nodeDescription: PropType.string,
  nodeProviders: PropType.arrayOf(PropType.any).isRequired,
}

export default NodeInfoExtended
