import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    actionArea: {
      width: '100%',
      marginLeft: '-8px',
    },
  })
  const classes = useStyles()
  return classes
}
