import React from 'react'
import { Heading, hooks } from 'lq-ui'
import privacyStatement from 'messages/privacyStatement'
import styles from './ImpressumStyles'

const Impressum = () => {
  const classes = styles()
  return (
    <div className={classes.impressumContent}>
      <Heading level="h5" color="#085986">
        <b>{hooks.i18n.useMessage('imprint.title.1')}</b>
      </Heading>
      <p style={{ color: '#085986' }}>
        {hooks.i18n.useMessage('imprint.bottom.5')}
        <br />
        <br />
        <Heading level="h6" color="#085986">
          {hooks.i18n.useMessage('imprint.title.2')}
        </Heading>
        <br />
        {hooks.i18n.useMessage('imprint.bottom.6')}
        <br />
        <br />
        <Heading level="h5" color="#085986">
          {privacyStatement['header.0']}
        </Heading>
        <br />
        <Heading level="h6" color="#085986">
          {privacyStatement['header.1']}
        </Heading>
        <br />
        {privacyStatement['paragraph.1']}
        <br />
        <br />
        <Heading level="h6" color="#085986">
          {privacyStatement['header.2']}
        </Heading>
        <br />
        {privacyStatement['paragraph.2']}
        <br />
        <br />
        <Heading level="h6" color="#085986">
          {privacyStatement['header.3']}
        </Heading>
        <br />
        {privacyStatement['paragraph.3']}
        <br />
        <br />
        <Heading level="h6" color="#085986">
          {privacyStatement['header.4']}
        </Heading>
        <br />
        {privacyStatement['paragraph.4']}
        <br />
        <br />
        <Heading level="h6" color="#085986">
          {privacyStatement['header.5']}
        </Heading>
        <br />
        {privacyStatement['paragraph.5']}
        <br />
      </p>
    </div>
  )
}

export default Impressum
