import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Paper, Button, Card, Divider, Heading,
} from 'lq-ui'
import useNodeInfo from 'services/useNodeInfo'
import NodeConditions from 'components/NodeConditions'
import NodeHeader from 'components/ProviderName'
import { resetCriteriaValues } from 'redux/actions'
import { nodeSelector, computeGraphByConditions } from 'redux/selectors'

import Persona from 'components/Persona'
import useStyles from './ConfiguratorBoxStyles'


const ConfiguratorBox = () => {
  const dispatch = useDispatch()
  const handleClick = () => dispatch(resetCriteriaValues({}))
  const { conditions } = useSelector((state) => state.appState)
  const selectedNode = useSelector(nodeSelector)
  const [node] = useNodeInfo()
  const classes = useStyles()
  const graph = useSelector(computeGraphByConditions)
  const { nodes } = graph
  const IKTrue = nodes.some(({ label }) => label === 'Integrationskurs' ||label === 'IK Alpha' || label === 'IK Zweitschrift' || label === 'IK Jugend' || label === 'IK Eltern' || label === 'IK Eltern + Kinderbetr.')
  const IKIKTrue = nodes.some(({ label }) => label === 'Integrationskurs')
  const IKAlphaTrue = nodes.some(({ label }) => label === 'IK Alpha')
  const IKZweitTrue = nodes.some(({ label }) => label === 'IK Zweitschrift')
  const IKJugendTrue = nodes.some(({ label }) => label === 'IK Jugend')
  const IKElternTrue = nodes.some(({ label }) => label === 'IK Eltern' || label === 'IK Eltern + Kinderbetr.')
  const IKDoneTrue = () => {
    let dtzErgebnis = false
    if (Object.keys(conditions).some((key) => key === 'DTZ Ergebnis')){
      Object.entries(conditions).map((entry)=> {
        if (entry[0] === 'DTZ Ergebnis' && entry[1] !== 'keins' && entry[1] !== '')  return dtzErgebnis = true
        return
      })
    }
    return dtzErgebnis
  }
  
  return (
    <Paper className={classes.paper}>
      { !Object.keys(conditions).length
        && (
        <Card className={classes.nodeConditions}>
          <Card.Content className={classes.heading}>
            <Heading
              color="#085986"
              level="h6"
              className={classes.heading}
            >
              Hier finden Sie passende Sprachkursangebote.
              <br />
              Folgen Sie dazu einfach der Schritt-für-Schritt-Anleitung.
              Mit einem Klick auf die Ergebnisse erhalten Sie nähere Informationen zu den Anbietern.
              <br />
              <br />
            </Heading>

            <Heading level="h5" className={classes.heading}>
              Bitte konfigurieren Sie eine Person, um die individuelle Bildungskette anzuzeigen
            </Heading>
          </Card.Content>
        </Card>
        )}
      <div className={classes.panelHeader}>
        {Object.keys(conditions).length > 0
        && (
          <>
            <Button
              className={classes.button}
              variant="default"
              onClick={() => handleClick()}
            >
              Person zurücksetzen
            </Button>

          </>
        )}
        {node && (
          <NodeHeader title={node.name} noButton />
        )}
      </div>
      <div>
        {
          (!selectedNode && Object.keys(conditions).length === 0)
          && <Persona />
        }
      </div>
      <Divider light />
      { (Object.keys(conditions).length !== 0)
      && <NodeConditions />}
      {IKIKTrue && Object.keys(conditions).length > 0 && (
      <>
        <br />
        <br />
        <Heading
          color="#085986"
          level="h6"
          className={classes.iktrue}
        >
          Nähere Informationen zum Integrationskurs finden Sie auf der
          {' '}
          <a href="https://bamf-navi.bamf.de/de/Themen/Integrationskurse/?coord=467895.5763431&r=3&kursart=1&freiePlaetze=1&" target="_blank" rel="noopener noreferrer">
            Internet Seite des BAMF
          </a>
          . 
        </Heading>
      </>
      )}
      {IKAlphaTrue && Object.keys(conditions).length > 0 && (
      <>
        <br />
        <br />
        <Heading
          color="#085986"
          level="h6"
          className={classes.iktrue}
        >
          Nähere Informationen zum Integrationskurs Alphabetisierung finden Sie auf der
          {' '}
          <a href="https://bamf-navi.bamf.de/de/Themen/Integrationskurse/?coord=467895.5763431&r=3&kursart=4&freiePlaetze=1&" target="_blank" rel="noopener noreferrer">
            Internet Seite des BAMF
          </a>
          . 
        </Heading>
      </>
      )}
      {IKZweitTrue && Object.keys(conditions).length > 0 && (
      <>
        <br />
        <br />
        <Heading
          color="#085986"
          level="h6"
          className={classes.iktrue}
        >
          Nähere Informationen zum Integrationskurs Zweitschrift finden Sie auf der
          {' '}
          <a href="https://bamf-navi.bamf.de/de/Themen/Integrationskurse/?coord=467895.5763431&r=3&kursart=19&freiePlaetze=1&" target="_blank" rel="noopener noreferrer">
            Internet Seite des BAMF
          </a>
         . 
        </Heading>
      </>
      )}
      {IKJugendTrue && Object.keys(conditions).length > 0 && (
      <>
        <br />
        <br />
        <Heading
          color="#085986"
          level="h6"
          className={classes.iktrue}
        >
          Nähere Informationen zum Integrationskurs für Jugendliche finden Sie auf der
          {' '}
          <a href="https://bamf-navi.bamf.de/de/Themen/Integrationskurse/?coord=467895.5763431&r=3&kursart=2&freiePlaetze=1&" target="_blank" rel="noopener noreferrer">
            Internet Seite des BAMF
          </a>
          . 
        </Heading>
      </>
      )}
      {IKElternTrue && Object.keys(conditions).length > 0 && (
      <>
        <br />
        <br />
        <Heading
          color="#085986"
          level="h6"
          className={classes.iktrue}
        >
          Nähere Informationen zum Integrationskurs speziell für Eltern finden Sie auf der
          {' '}
          <a href="https://bamf-navi.bamf.de/de/Themen/Integrationskurse/?coord=467895.5763431&r=3&kursart=6&freiePlaetze=1&" target="_blank" rel="noopener noreferrer">
            Internet Seite des BAMF
          </a>
          . 
        </Heading>
      </>
      )}
      {IKDoneTrue() && Object.keys(conditions).length > 0 && (
      <>
        <br />
        <br />
        <Heading
          color="#085986"
          level="h6"
          className={classes.iktrue}
        >
          Nähere Informationen zum DeuFöV-Angebote im Kursnet finden Sie hier:
          {' '}
          <a href="https://web.arbeitsagentur.de/sprachfoerderung/suche/berufssprachkurse?ort=Bielefeld_8.5322_52.0206&umkreis=10&seite=0" target="_blank" rel="noopener noreferrer">
            Internet Seite von Kursnet
          </a>
          . 
        </Heading>
      </>
      )}
    </Paper>
  )
}

export default ConfiguratorBox
