import { useJss } from 'lq-ui'

export default () => {
  const bottomBarHeight = {
    height: '60px',
  }
  const icons = {
    ...bottomBarHeight,
    cursor: 'pointer',
    paddingRight: '5px',
  }
  const useStyles = useJss({
    bottomBar: {
      ...bottomBarHeight,
      width: '80%',
      display: 'inline-flex',
      justifyContent: 'flex-start',
    },
    pageBottom: {
      left: '0',
      bottom: '0',
      width: '100%',
      position: 'absolute',
    },
    appBar: {
      height: '60px !important',
      width: '100% !important',
      backgroundColor: 'white !important',
      display: 'flex !important',
      '& div': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    ak: {
      width: '250px',
      ...icons,
    },
    eg: {
      width: '250px',
      ...icons,
    },
    ki: {
      width: '150px',
      ...icons,
    },
    rege: {
      width: '150px',
      ...icons,
    },
    sd: {
      width: '120px',
      ...icons,
    },
    footer: {
      width: '97%',
      marginBottom: '-5px',
      maxHeight: '13rem',
    },
    link: {
      color: '#085986',
      textAlign: 'end',
      width: 'auto',
      fontSize: '11pt',
      textTransform: 'none',
    },
    logo: {
      marginBottom: '-5px',
    },
  })
  const classes = useStyles()
  return classes
}
