import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Button } from 'lq-ui'
import styles from './styles'

const AddElementButton = ({
  label, labelColor, backgroundColor, className, onClick, action,
}) => {
  const classes = styles(labelColor, backgroundColor)
  return (
    <Button onClick={onClick} className={`${classes.button} ${className}`}>
      <Icon iconName={action} color={labelColor} />
      {label && <span className={classes.label}>{label}</span>}
    </Button>
  )
}

AddElementButton.defaultProps = {
  className: '',
  backgroundColor: '#760074',
  label: '',
  labelColor: '#fff',
  action: 'Add',
}

AddElementButton.propTypes = {
  action: PropTypes.string,
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default AddElementButton
