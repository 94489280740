import React from 'react'
import { Heading, hooks } from 'lq-ui'
import { ReactComponent as RotatePhoneLogo } from 'assets/graphPage/rotatePhone.svg'


const MobileRotationHint = () => (
  <div style={{ margin: '10px' }}>
    <br />
    <Heading level="h5" color="#085986">
      <b>{hooks.i18n.useMessage('graph.mobileRotationHint')}</b>
    </Heading>
    <br />
    <RotatePhoneLogo />
  </div>
)


export default MobileRotationHint
