import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, Button, hooks } from 'lq-ui'
import { ReactComponent as Logo } from 'assets/landingPage/bielefeldSkyline.svg'
import { ReactComponent as AK } from 'assets/landingPage/AK.svg'
import { ReactComponent as EG } from 'assets/landingPage/EG.svg'
import { ReactComponent as REGE } from 'assets/landingPage/REGE.svg'
import { ReactComponent as SD } from 'assets/landingPage/SD.svg'
import { ReactComponent as KI } from 'assets/landingPage/KI.svg'
import pages from 'pages/routes.json'
import styles from './BottomNavigationStyles'

const BottomNavigation = ({ title, showSkyline }) => {
  const openLink = (link) => window.open(link, '_blank')
  const router = hooks.router.useRouter()
  const classes = styles()
  return (
    <div className={classes.pageBottom}>
      {!!showSkyline && <Logo className={classes.logo} />}
      <AppBar className={classes.appBar} position="static">
        <div className={classes.bottomBar}>
          <REGE
            className={classes.rege}
            onClick={() => openLink('http://www.rege-mbh.de')}
          />
          <SD
            className={classes.sd}
            onClick={() => openLink('https://www.bielefeld.de/de/rv/org_verwaltung/dezernat5')}
          />
          <KI
            className={classes.ki}
            onClick={() => openLink('https://www.bielefeld.de/de/rv/ds_stadtverwaltung/int')}
          />
          <AK
            className={classes.ak}
            onClick={() => openLink('https://www.mkffi.nrw')}
          />
          <EG
            className={classes.eg}
            onClick={() => openLink('https://www.mkffi.nrw')}
          />
        </div>
        <Button
          className={classes.link}
          onClick={() => router.push(pages.impressum)}
        >
          <b>{ title }</b>
        </Button>
      </AppBar>
    </div>
  )
}

BottomNavigation.defaultProps = {
  showSkyline: false,
}


BottomNavigation.propTypes = {
  title: PropTypes.string.isRequired,
  showSkyline: PropTypes.bool,
}

export default BottomNavigation
