import React from 'react'
import EditorActionButton from 'components/EditorActionButton'
import { useAuth, hooks } from 'lq-ui'
import styles from './ProviderEditButtonStyles'

const ProviderEditButton = ({
  value, onChange, handleUpdate, handleDelete, isProposal,
}) => {
  const auth = useAuth()
  const classes = styles()
  const handleSave = () => {
    onChange(!value)
    handleUpdate()
  }
  const deleteItem = () => {
    handleDelete()
  }
  const handleClick = () => {
    onChange(!value)
  }
  const displayButton = isProposal || auth.user
  const saveLabel = hooks.i18n.useMessage('editor.addNewNode.save.button')
  const editLabel = hooks.i18n.useMessage('general.edit')
  const deleteLabel = hooks.i18n.useMessage('general.delete')

  return displayButton
    && (
      value
        ? (
          <span className={classes.button}>
            <EditorActionButton
              action="Save"
              className={classes.buttonWidth}
              label={saveLabel}
              onClick={handleSave}
              backgroundColor="#098880"
            />
          </span>
        )
        : (
          <>
            <span className={classes.button}>
              <EditorActionButton
                action="Edit"
                className={classes.buttonWidth}
                label={editLabel}
                onClick={handleClick}
              />
            </span>
            {handleDelete
            && (
            <span className={classes.button}>
              <EditorActionButton
                action="Delete"
                className={classes.buttonWidth}
                label={deleteLabel}
                onClick={deleteItem}
              />
            </span>
            )}
          </>
        )
    )
}

export default ProviderEditButton
