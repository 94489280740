import React from 'react'
import useProviders from 'services/useProviders'
import ProvidersList from 'components/ProvidersList'
import ProviderMenu from 'components/ProviderMenu'
import ProvidersTop from 'components/ProvidersTop'
import { Grid, hooks } from 'lq-ui'
import pages from 'pages/routes.json'
import EditorActionButton from 'components/EditorActionButton'
import ProviderForm from 'components/ProviderForm'
import { useAuth } from 'lq-ui/lib/auth/use-auth'
import styles from './ProvidersStyles'


const Providers = () => {
  const classes = styles()
  const providers = useProviders()

  const router = hooks.router.useRouter()
  const [printProviders, setPrintProviders] = hooks.window.usePrint()
  const providerMenuItems = [
    { route: pages.providers.type.language, title: hooks.i18n.useMessage('providers.type.language') },
    { route: pages.providers.type.job, title: hooks.i18n.useMessage('providers.type.job') },
    { route: pages.providers.type.approval, title: hooks.i18n.useMessage('providers.type.approval') },
    { route: pages.providers.type.apprenticeship, title: hooks.i18n.useMessage('providers.type.apprenticeship') },
    { route: pages.providers.type.internship, title: hooks.i18n.useMessage('providers.type.internship') },
    { route: pages.providers.type.status, title: hooks.i18n.useMessage('providers.type.status') },
    { route: pages.providers.type.onboarding, title: hooks.i18n.useMessage('providers.type.onboarding') },
  ]

  const [addProvider, setAddProvider] = React.useState(false)
  const handleAddProvider = () => setAddProvider(!addProvider)
  const buttonLabel = hooks.i18n.useMessage(addProvider ? 'general.cancel' : 'editor.addNewProvider.button')
  const nextProviderId = Math.max(...providers.map((p) => p.id)) + 1

  const { user } = useAuth()

  // React.useEffect(() => {
  //   setAddProvider(false)
  // }, [providers])

  return (
    <>
      {user
      && (
      <div style={{ maxWidth: '300px', margin: '0 auto' }}>
        <EditorActionButton
          action={addProvider ? 'Cancel' : 'Add'}
          label={buttonLabel}
          onClick={handleAddProvider}
          backgroundColor={addProvider ? '#B00020' : '#085986'}
        />

      </div>
      )}
      <Grid
        type="container"
        flexDirection="row"
        justifyContent="center"
        alignItems="flex-start"
        className={classes.gridContainerPadding}
      >
        <Grid type="item" xs={12} lg={2} xl={1} className={classes.gridItemPadding}>
          <ProviderMenu
            providerMenu={providerMenuItems}
            menuRoute={pages.providers.root}
          />
        </Grid>
        {router.query.type && <Grid type="item" lg={1} xl={1}><ProvidersTop handlePrint={setPrintProviders} /></Grid>}

        <Grid type="item" xs={12} lg={9} xl={10} className={classes.gridItemPadding}>
          <Grid
            type="container"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            {addProvider && ( // need to find a way to close the form after submitting
            <Grid type="item" xs={3} sm={3} md={3} lg={3}>
              <ProviderForm
                isNew={nextProviderId}
                isEditor
                print={false}
                provider={null}
                showType
              />
            </Grid>
            )}
            <ProvidersList
              providers={providers}
              addProvider={addProvider}
              isPrinting={printProviders}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Providers
