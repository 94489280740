import React from 'react'
import PropTypes from 'prop-types'
import ProviderCard from 'components/ProviderCard'
import sortProviders from 'services/sortProviders'

const ProvidersList = ({
  print, providers, showDescription, isEditor, fixedWidth, isSideBar,
}) => {
  const sortedProviders = isEditor ? providers : sortProviders(providers)
  return sortedProviders.map((provider) => (
    <ProviderCard
      key={provider.id}
      provider={provider}
      isEditor={isEditor}
      isSideBar={isSideBar}
      showDescription={showDescription}
      print={print}
      fixedWidth={fixedWidth}
    />
  ))
}

ProvidersList.defaultProps = {
  print: false,
  showDescription: false,
  isEditor: false,
  isSideBar: false,
  fixedWidth: false,
}

ProvidersList.propTypes = {
  print: PropTypes.bool,
  isEditor: PropTypes.bool,
  isSideBar: PropTypes.bool,
  showDescription: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  providers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    map: PropTypes.string,
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
}

export default ProvidersList
