import { useJss } from 'lq-ui'

export default (labelColor, backgroundColor) => {
  const useStyles = useJss({
    buttonGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      // padding: '0 1rem',
    },
    label: {
      marginLeft: '5px',
      color: labelColor,
    },
    button: {
      padding: '.5rem 0',
      width: '100%',
      backgroundColor,
      '&:hover': {
        backgroundColor,
      },
    },
  })
  const classes = useStyles()
  return classes
}
